import * as React from "react";

import Stack from "@mui/material/Stack";

import useAuth from "app/hooks/useAuth";
import {
  ADD_USER,
  DELETE_USER,
  EDIT_DAHIYAMITRA,
  VIEW_USER,
} from "Permissions";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { Formik } from "formik";
import "moment/locale/en-in";
import { Button, Col, Grid, Heading, Row } from "rsuite";

import { Uploader, Message, Loader, useToaster, Button as Btn } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";

import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { AttachFile, DeleteForever, People } from "@mui/icons-material";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// TODO: Get tehsil, dist and state from Village name
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = yup.object({
  LoginEnabled: yup.string().required("Required field"),
  Fullname: yup.string().required("Valid Name required"),
  Firmname: yup.string().nullable(true),
  Officialemail: yup
    .string()
    .email("Valid Official Email required")
    .nullable(true),
  Remark: yup.string().nullable(true),
  Usertype: yup.string().required("Valid Type required"),
  Email: yup.string().email("Valid Email ID required").nullable(true),
  NewPassword: yup
    .string()
    .min(8, "Password too short")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
    .matches(/^(?=.*[0-9])/, "Must contain at least one number")
    .matches(/^(?=.*[!@#%&])/, "Must contain at least one special character")
    .nullable(true),
  Mobile: yup
    .string()
    .matches(/^\d{10}$/, "Phone number is not valid")
    .nullable(true),
  Officeno: yup
    .string()
    .matches(/^\d{10}$/, "Phone number is not valid")
    .nullable(true),
});

export default function ViewDahiyaMitra() {
  const { user, permissions } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { UID } = useParams();

  //const uploader = React.useRef();
  const [docs, setDocs] = React.useState([]);
  const [data, setData] = React.useState({});

  const toaster = useToaster();
  //const uploader = React.useRef();
  const [fileList1, setFileList1] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);

  const theme = useTheme();
  const [initialValues, setInitialValues] = React.useState({
    Fullname: "",
    Firmname: "",
    Email: "",
    Mobile: "",
    LoginEnabled: "1",
    Usertype: "1",
    Userid: "",
    NewPassword: "",
  });
  const initialState = {
    EditMode: false,
    Attachment: false,
  };
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "LOCKED":
        return { ...initialState, EditMode: false, Attachment: true };
      case "DONE":
        return { ...initialState, Attachment: false };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDahiyaMitra();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  const getDahiyaMitra = async () => {
    try {
      const responce = await axiosPrivate.post("GetDahiyaMitraByID.php", {
        UID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Fullname: result.Data[0].fullname,
          Firmname: result.Data[0].firmname,
          Usertype: result.Data[0].UserType,
          Userid: result.Data[0].Username,
          Address: result.Data[0].address,
          Remark: result.Data[0].remark,
          LoginEnabled: result.Data[0].Enabled === "1" ? "YES" : "NO",
          OfficialEmailID: result.Data[0].officialemail,
          OfficialContactNo: result.Data[0].officeno,
          DOB:
            result.Data[0].dob === "0000-00-00" || result.Data[0].dob === null
              ? null
              : result.Data[0].dob,
          Anniversary:
            result.Data[0].anniversary === "0000-00-00" ||
            result.Data[0].anniversary === null
              ? null
              : result.Data[0].anniversary,
          DOJ:
            result.Data[0].joiningdate === "0000-00-00" ||
            result.Data[0].joiningdate === null
              ? null
              : result.Data[0].joiningdate,
        };
        setInitialValues(newData);

        setFileInfo(
          `https://LICBackend.avtechsolutions.site/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setData({ ...data, ...result.Data[0] });
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DONE" });
                onClose();
                setFileList1([]);
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={5}
            style={{
              textAlign: "center",
              flex: 0.8,
            }}
          >
            {`View/Edit Dahiya Mitra - (${initialValues.fullname})`}
          </Heading>
          <Stack
            direction="row"
            spacing={2}
            style={{ flex: 0.2, justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => {
                navigate(`/FamilyMember/User/${UID}`);
              }}
              appearance="link"
              startIcon={<People />}
            >
              Family Details
            </Button>
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  console.log(values);
                  axiosPrivate
                    .post("EditDahiyaMitra.php", {
                      ...values,

                      DOB: values?.DOB
                        ? moment(values.DOB).format("YYYY-MM-DD")
                        : null,
                      Anniversary: values?.Anniversary
                        ? moment(values.Anniversary).format("YYYY-MM-DD")
                        : null,
                      DOJ: values?.DOJ
                        ? moment(values.DOJ).format("YYYY-MM-DD")
                        : null,
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        console.log(result);
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result?.Success}
                          </Message>
                        );
                        dispatch({ type: "LOCKED" });
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setValues,
                  setErrors,
                  setTouched,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Login?
                      </Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Type
                          </InputLabel>
                          <Select
                            disabled={!state.EditMode}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="LoginEnabled"
                            name="LoginEnabled"
                            value={values?.LoginEnabled || ""}
                            onChange={handleChange}
                            error={
                              touched.LoginEnabled &&
                              Boolean(errors.LoginEnabled)
                            }
                            helperText={
                              touched.LoginEnabled && errors.LoginEnabled
                            }
                          >
                            {["YES", "NO"].map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, ["YES", "NO"], theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        &nbsp; &nbsp;
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            User Type
                          </InputLabel>
                          <Select
                            disabled={!state.EditMode}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Usertype"
                            name="Usertype"
                            value={values?.Usertype || ""}
                            onChange={handleChange}
                            error={touched.Usertype && Boolean(errors.Usertype)}
                            helperText={touched.Usertype && errors.Usertype}
                          >
                            {["DahiyaMitra"].map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, ["DahiyaMitra"], theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.EditMode}
                            label="Date Of Joining"
                            variant="standard"
                            size="small"
                            name="DOJ"
                            format="dd-MM-yyyy"
                            value={values.DOJ}
                            onChange={(value) =>
                              setFieldValue("DOJ", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOJ && Boolean(errors.DOJ)}
                            helperText={touched.DOJ && errors.DOJ}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Personal details</Heading>
                    </Row>
                    {/* <br /> */}
                    <Row gutter={1} className="show-grid">
                      <Stack direction="row" spacing={2}>
                        <TextField
                          disabled={!state.EditMode}
                          label="Full Name"
                          size="small"
                          variant="standard"
                          name="Fullname"
                          InputLabelProps={{
                            shrink: Boolean(values.Fullname),
                          }}
                          value={values.Fullname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Fullname && Boolean(errors.Fullname)}
                          helperText={touched.Fullname && errors.Fullname}
                          style={{ width: 300 }}
                        />{" "}
                      </Stack>
                    </Row>
                    <Row
                      gutter={1}
                      className="show-grid"
                      style={{ marginTop: 10 }}
                    >
                      <Stack direction="row" style={{ alignItems: "flex-end" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.EditMode}
                            label="Date Of Birth"
                            variant="standard"
                            size="small"
                            name="DOB"
                            format="dd-MM-yyyy"
                            value={values.DOB}
                            onChange={(value) =>
                              setFieldValue("DOB", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.DOB && Boolean(errors.DOB)}
                            helperText={touched.DOB && errors.DOB}
                          />
                        </LocalizationProvider>
                        &nbsp;
                        <b>
                          {values.DOB &&
                            `${moment().diff(
                              values.DOB ? new Date(values.DOB) : null,
                              "years"
                            )} Year(s)`}
                        </b>
                        &nbsp; &nbsp;
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={!state.EditMode}
                            label="Date Of Anniversary"
                            variant="standard"
                            size="small"
                            name="Anniversary"
                            format="dd-MM-yyyy"
                            value={values.Anniversary}
                            onChange={(value) =>
                              setFieldValue("Anniversary", value, true)
                            }
                            onBlur={handleBlur}
                            error={
                              touched.Anniversary && Boolean(errors.Anniversary)
                            }
                            helperText={
                              touched.Anniversary && errors.Anniversary
                            }
                          />
                        </LocalizationProvider>
                        &nbsp; &nbsp;
                        <b>
                          {values.Anniversary &&
                            `${moment().diff(
                              values.Anniversary
                                ? new Date(values.Anniversary)
                                : null,
                              "years"
                            )} Year(s)`}
                        </b>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Contacts details</Heading>
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        disabled={!state.EditMode}
                        label="Email ID"
                        size="small"
                        variant="standard"
                        style={{ width: 250 }}
                        name="Email"
                        InputLabelProps={{
                          shrink: Boolean(values.Email),
                        }}
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.Email && Boolean(errors.Email)}
                        helperText={touched.Email && errors.Email}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={!state.EditMode}
                        label="Mobile"
                        size="small"
                        variant="standard"
                        style={{ width: 150 }}
                        name="Mobile"
                        value={values.Mobile}
                        InputLabelProps={{
                          shrink: Boolean(values.Mobile),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.Mobile && Boolean(errors.Mobile)}
                        helperText={touched.Mobile && errors.Mobile}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <TextField
                        disabled={!state.EditMode}
                        label="Address"
                        size="small"
                        variant="standard"
                        style={{ width: 300 }}
                        name="Address"
                        value={values.Address}
                        InputLabelProps={{
                          shrink: Boolean(values.Address),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.Address && Boolean(errors.Address)}
                        helperText={touched.Address && errors.Address}
                      />{" "}
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Firm details</Heading>
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        disabled={!state.EditMode}
                        label="Firm name"
                        size="small"
                        variant="standard"
                        style={{ width: 150 }}
                        name="Firmname"
                        InputLabelProps={{
                          shrink: Boolean(values.Firmname),
                        }}
                        value={values.Firmname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.Firmname && Boolean(errors.Firmname)}
                        helperText={touched.Firmname && errors.Firmname}
                      />{" "}
                      &nbsp;&nbsp;
                      <TextField
                        disabled={!state.EditMode}
                        label="Official Email ID"
                        size="small"
                        variant="standard"
                        style={{ width: 200 }}
                        name="OfficialEmailID"
                        value={values.OfficialEmailID}
                        InputLabelProps={{
                          shrink: Boolean(values.OfficialEmailID),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.OfficialEmailID &&
                          Boolean(errors.OfficialEmailID)
                        }
                        helperText={
                          touched.OfficialEmailID && errors.OfficialEmailID
                        }
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 20, marginBottom: 10 }}>
                      <TextField
                        disabled={!state.EditMode}
                        label="Official Contact No"
                        size="small"
                        variant="standard"
                        style={{ width: 200 }}
                        name="OfficialContactNo"
                        value={values.OfficialContactNo}
                        InputLabelProps={{
                          shrink: Boolean(values.OfficialContactNo),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.OfficialContactNo &&
                          Boolean(errors.OfficialContactNo)
                        }
                        helperText={
                          touched.OfficialContactNo && errors.OfficialContactNo
                        }
                      />{" "}
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Login details</Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={1}>
                        <TextField
                          disabled={!state.EditMode}
                          label="User ID"
                          size="small"
                          variant="standard"
                          style={{ width: 100 }}
                          name="Userid"
                          value={values.Userid}
                          InputLabelProps={{
                            shrink: Boolean(values.Userid),
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Userid && Boolean(errors.Userid)}
                          helperText={touched.Userid && errors.Userid}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={!state.EditMode}
                          label="New Password"
                          size="small"
                          variant="standard"
                          style={{ width: 250 }}
                          name="NewPassword"
                          value={values.NewPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.NewPassword && Boolean(errors.NewPassword)
                          }
                          helperText={touched.NewPassword && errors.NewPassword}
                        />{" "}
                      </Stack>
                    </Row>
                    &nbsp; &nbsp;
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Remark</Heading>
                    </Row>
                    {/* <br /> */}
                    <Row gutter={1} className="show-grid">
                      <TextField
                        disabled={!state.EditMode}
                        label="Remark"
                        size="small"
                        variant="standard"
                        multiline={true}
                        maxRows={4}
                        style={{ width: 250 }}
                        name="Remark"
                        InputLabelProps={{
                          shrink: Boolean(values.Remark),
                        }}
                        value={values.Remark}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.Remark && Boolean(errors.Remark)}
                        helperText={touched.Remark && errors.Remark}
                      />{" "}
                    </Row>
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {state.EditMode ? (
                          <>
                            <LoadingButton
                              disabled={isSubmitting}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              onClick={handleSubmit}
                              loading={isValidating}
                              sx={{ my: 2 }}
                            >
                              {isSubmitting ? "Submitting..." : "Submit"}
                            </LoadingButton>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                setErrors({});
                                setTouched({}, false);

                                dispatch({ type: "DONE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"CANCEL"}
                            </Btn>
                          </>
                        ) : (
                          <>
                            <Btn
                              color="secondary"
                              variant="contained"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch({ type: "EDITABLE" });
                              }}
                              sx={{ my: 2 }}
                            >
                              {"Edit"}
                            </Btn>
                            {state.Attachment && (
                              <Btn
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAttachment();
                                }}
                                sx={{ my: 2 }}
                              >
                                {"DONE"}
                              </Btn>
                            )}
                          </>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                {UID && (
                  <Col xs={24} sm={24} md={24}>
                    <Uploader
                      fileListVisible={false}
                      disabled={!state.Attachment}
                      multiple={false}
                      listType="picture"
                      action="https://LICBackend.avtechsolutions.site/Upload/AddProfilePic.php"
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      data={{ UID, Type: "User" }}
                      onUpload={(File) => {
                        setUploading(true);
                        previewFile(File.blobFile, (value) => {
                          setFileInfo(value);
                        });
                      }}
                      onSuccess={(response, _) => {
                        setUploading(false);
                        toaster.push(
                          <Message type="success">
                            Uploaded successfully
                          </Message>
                        );
                        console.log(response);
                      }}
                      onError={(e) => {
                        console.log(e);
                        setFileInfo(null);
                        setUploading(false);
                        toaster.push(
                          <Message type="error">Upload failed</Message>
                        );
                      }}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                    >
                      <button style={{ width: 300, height: 300 }}>
                        {uploading && <Loader backdrop center />}
                        {fileInfo ? (
                          <img
                            alt=""
                            src={fileInfo}
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <AvatarIcon style={{ fontSize: 80 }} />
                        )}
                      </button>
                    </Uploader>

                    <Uploader
                      disabled={!state.Attachment}
                      removable={!state.Attachment}
                      onRemove={(file) => {
                        console.log(file);
                      }}
                      listType="picture-text"
                      fileList={fileList1}
                      multiple={true}
                      data={{ UID, Type: "DahiyaMitra" }}
                      onChange={setFileList1}
                      withCredentials={true}
                      headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                      action="https://LICBackend.avtechsolutions.site/Upload/UploadDocs.php"
                      renderFileInfo={(file, fileElement) => (
                        <span style={{ width: "100%", fontSize: 14 }}>
                          Name: {file.name}
                        </span>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://LICBackend.avtechsolutions.site/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_DAHIYAMITRA) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}
