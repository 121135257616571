import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  Col,
  Container,
  Content,
  Divider,
  Grid,
  Loader,
  Message,
  Radio,
  RadioGroup,
  Row,
  Uploader,
  useToaster,
} from "rsuite";
import { Autocomplete, Button, Drawer, TextField } from "@mui/material";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import {
  CalendarMonth,
  DeleteForever,
  ListAltRounded,
  People,
  PersonAdd,
  VerifiedUserSharp,
} from "@mui/icons-material";
import Header from "app/components/Header";
import "../../pages/styles.css";
import useAuth from "app/hooks/useAuth";
import { ADD_CLIA, EDIT_PROSPECT, VIEW_CLIA } from "Permissions";
import { useNavigate, useParams } from "react-router-dom";
import SideDrawer from "app/components/SideDrawer";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Field, Formik } from "formik";
import "dayjs/locale/en-in";
import SideInputs from "../Inputs/SideInputs";
import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import { useApi } from "app/hooks/useApi";
import moment from "moment";
import * as yup from "yup";
import AgGrid from "app/components/AgGrid";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { filterParams } from "functions";

function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
const today = new Date();
today.setHours(0, 0, 0, 0);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  DOBG: yup.date().max(today, "DOB can not be in future").nullable(true),
  DOBR: yup.date().max(today, "DOB can not be in future").nullable(true),
  Name: yup.string("valid name is required").required("Name is required"),

  MobileNo: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(true),

  Relation: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  Health: yup
    .string("valid education is required")
    .required("Education is required"),
  WorkPlace: yup.string("valid workplace is required"),
  Education: yup.string("valid Education is required"),
  OldLIC: yup.string().required("Please enter Yes Or No"),
});

export default function FamilyMembers() {
  const [open, setOpen] = React.useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { UID, Ref } = useParams();
  const [familyID, setFamilyID] = React.useState(null);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [familyHeadData, setFamilyHeadData] = React.useState({});
  const [rowData, setRowData] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [fileList1, setFileList1] = React.useState([]);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const { user, permissions } = useAuth();
  const handleOpen = () => setOpenModal(true);

  const handleClose = () => {
    setOpenModal(false);
    setFileInfo(null);
    setFileList1([]);
    setDocs([]);
  };
  const handleViewModalOpen = () => setOpenViewModal(true);

  const handleViewModalClose = () => {
    setOpenViewModal(false);
    setFileInfo(null);
    setFileList1([]);
    setDocs([]);
  };

  const [initialValues, setInitialValues] = React.useState({
    Name: null,
    DOBG: new Date(),
    DOBM: new Date(),
    DOM: new Date(),
    Health: null,
    Work: null,
    Relation: null,
    WorkPlace: null,
    WorkType: null,
    Education: null,
    OldLIC: null,
    MobileNo: null,
    ID: null,
    Gender: null,
  });

  const GetFamilyByID = async (ID) => {
    try {
      const responce = await axiosPrivate.post("GetFamilyByID.php", {
        UID: ID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          MobileNo: result.Data[0].Mobile,
          WorkPlace: result.Data[0].Workplace,
          Work:
            result.Data[0].Work === ""
              ? null
              : {
                  value: result.Data[0].Work,
                  title: result.Data[0].Work,
                },
          WorkType:
            result.Data[0].WorkType === ""
              ? null
              : {
                  value: result.Data[0].WorkType,
                  title: result.Data[0].WorkType,
                },
          Relation:
            result.Data[0].Relation === ""
              ? null
              : {
                  value: result.Data[0].Relation,
                  title: result.Data[0].Relation,
                },

          DOBR:
            result.Data[0].DOBR === "0000-00-00" || result.Data[0].DOBR === null
              ? null
              : new Date(result.Data[0].DOBR),
          DOBG:
            result.Data[0].DOBG === "0000-00-00" || result.Data[0].DOBG === null
              ? null
              : new Date(result.Data[0].DOBG),
          DOM:
            result.Data[0].DOM === "0000-00-00" || result.Data[0].DOM === null
              ? null
              : new Date(result.Data[0].DOM),
          UID: ID,
        };
        setInitialValues(newData);
        setFileInfo(
          `https://LICBackend.avtechsolutions.site/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        /*         console.log(result.Docs);
        console.log(
          `https://LICBackend.avtechsolutions.site/Upload/profile/${result.Data[0].Picture}`
        ); */
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  const GetFamilyHeadByID = async () => {
    try {
      const responce = await axiosPrivate.post(`Get${Ref}FamilyHead.php`, {
        UID,
      });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        setFamilyHeadData(result.Data[0]);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
        setFamilyHeadData({});
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) GetFamilyHeadByID();
    return () => {
      isSubscribed = false;
    };
  }, []);

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      if (selectedRowData?.UniqueID) {
        GetFamilyByID(selectedRowData.UniqueID);
      }
    return () => {
      isSubscribed = false;
    };
  }, [selectedRowData?.UniqueID, selectedRowData]);

  const columns = React.useMemo(() => {
    return [
      { field: "Name", minWidth: 150 },
      { field: "Relation", minWidth: 150 },
      { field: "Mobile", minWidth: 150, type: "numericColumn" },
      { field: "Gender", minWidth: 150 },
      {
        field: "DOBG",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "DOBR",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      {
        field: "DOM",
        minWidth: 150,
        type: "date",
        filter: "agDateColumnFilter",
        filterParams: filterParams,
      },
      { field: "Health", minWidth: 150 },
      { field: "Education", minWidth: 150 },
      { field: "Work", minWidth: 150 },
      { field: "WorkType", minWidth: 150 },
      { field: "Workplace", minWidth: 150 },
      { field: "OldLIC", minWidth: 150 },

      { field: "AddedBy", minWidth: 150 },
      { field: "AddedOn", minWidth: 150 },
      { field: "UniqueID", hide: true },
    ];
  }, []);

  const onGridReady = React.useCallback((params) => {
    setLoading(true);
    axiosPrivate
      .post("LoadFamilyMember.php", { UID })
      .then((responce) => {
        const result = responce.data;
        if (result.status === 1) setRowData(result.Data);
        else setRowData([]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const ProfessionList = useApi("GetProfessionList.php");
  const WorkTypeList = useApi("GetWorkTypeList.php");
  const FamilyList = useApi("GetFamilyTypeList.php");
  const toaster = useToaster();

  function SaveFamily(FamilyData) {
    const newData = {
      ...FamilyData,
      DOBR: FamilyData.DOBR
        ? moment(FamilyData.DOBR?.$d).format("YYYY-MM-DD")
        : null,
      DOBG: FamilyData.DOBG
        ? moment(FamilyData.DOBG?.$d).format("YYYY-MM-DD")
        : null,

      DOM: FamilyData.DOM
        ? moment(FamilyData.DOM?.$d).format("YYYY-MM-DD")
        : null,
      Work: FamilyData.Work.value,
      WorkType: FamilyData.WorkType.value,
      Relation: FamilyData.Relation.value,
      ReferenceID: UID,
    };

    axiosPrivate
      .post("NewFamilyMember.php", newData)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result.Success}
            </Message>
          );
          setFamilyID(result.ID);
          onGridReady();
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }

        // resetForm();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  }

  function UpdateFamily(FamilyData) {
    const newData = {
      ...FamilyData,
      DOBR: FamilyData.DOBR
        ? moment(FamilyData?.DOBR).format("YYYY-MM-DD")
        : null,

      DOBG: FamilyData?.DOBG
        ? moment(FamilyData?.DOBG).format("YYYY-MM-DD")
        : null,
      DOM: FamilyData?.DOM
        ? moment(FamilyData?.DOM).format("YYYY-MM-DD")
        : null,
      Work: FamilyData.Work.value,
      WorkType: FamilyData.WorkType.value,
      Relation: FamilyData.Relation.value,
    };

    axiosPrivate
      .post("UpdateFamilyMember.php", newData)
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result.Success}
            </Message>
          );
          setFamilyID(result.ID);
          onGridReady();
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }

        // resetForm();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  }

  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this  details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: initialValues.UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveFamilyMember.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  function headerComponent() {
    console.log(familyHeadData);
    return (
      <p>
        <b>Family Head : </b> {`${familyHeadData.Name} - ${familyHeadData.ID}`}{" "}
      </p>
    );
  }

  return (
    <Box
      component="section"
      sx={{
        p: 1,
        border: "0.5px dashed #ddd",
        width: "100%",
        backgroundColor: "#FEFAF6",
      }}
    >
      <Stack spacing={1}>
        <Header headerText={`${Ref} Family Members`}></Header>

        <Container>
          <Content
            style={{
              flex: 1,
              width: "100%",
              height: 650,
              borderRadius: 10,
              border: "1px dashed #ddd",
            }}
          >
            <>
              <AgGrid
                rowData={rowData}
                columns={columns}
                onGridReady={onGridReady}
                defaultColumn={["Name"]}
                showLoading={loading}
                addMember={true}
                handleOpen={handleOpen}
                onRowSelectedData={setSelectedRowData}
                handleViewModalOpen={handleViewModalOpen}
                headerComponent={headerComponent}
                setFilteredRows={setFilteredRows}
                Source="Family"
              />

              <SideDrawer
                open={openModal}
                onClose={() => {
                  handleClose();
                  setFamilyID(null);
                  setFileInfo(null);
                  setFileList1([]);
                }}
                title="Add Family Members"
              >
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    Name: null,
                    DOBR: null,
                    DOBG: null,
                    DOM: null,
                    Health: null,
                    Work: null,
                    Relation: null,
                    WorkPlace: null,
                    OldLIC: null,
                    MobileNo: null,
                    Education: null,
                    WorkType: null,
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    SaveFamily(values);
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    isValidating,
                    isSubmitting,
                    touched,
                    handleBlur,
                    handleChange,
                  }) => {
                    console.log(errors);
                    console.log(values);
                    return (
                      <form>
                        <div>
                          <div className="row">
                            <Grid fluid style={{ padding: 4 }}>
                              <Row className="show-grid">
                                <Col xs={24} sm={24} md={24}>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <Field
                                      disabled={familyID}
                                      fullWidth
                                      name={`Name`}
                                      variant="standard"
                                      label="Name"
                                      size="small"
                                      as={TextField}
                                      placeholder="Enter Name"
                                      onBlur={handleBlur}
                                      error={
                                        touched.Name && Boolean(errors.Name)
                                      }
                                      helperText={touched.Name && errors.Name}
                                    />
                                  </Row>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                      adapterLocale="en-IN"
                                    >
                                      <Field
                                        disabled={familyID}
                                        name={`DOBR`}
                                        label="DOB (Record)"
                                        variant="standard"
                                        size="small"
                                        format="DD-MM-YYYY"
                                        as={DateField}
                                        value={values.DOBR}
                                        onChange={(value) => {
                                          setFieldValue(`DOBR`, value, true);
                                          setFieldValue(`DOBG`, value, true);
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.DOBR && Boolean(errors.DOBR)
                                        }
                                        helperText={touched.DOBR && errors.DOBR}
                                      />
                                    </LocalizationProvider>
                                    &nbsp; &nbsp;
                                    <b>
                                      {values.DOBR &&
                                        `${moment().diff(
                                          values.DOBR
                                            ? new Date(values.DOBR)
                                            : null,
                                          "years"
                                        )} Year(s)`}
                                    </b>
                                  </Row>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      alignItems: "flex-end",
                                      display: "flex",
                                    }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Field
                                        disabled={familyID}
                                        name={`DOBG`}
                                        label="DOB (Greeting)"
                                        variant="standard"
                                        size="small"
                                        format="DD-MM-YYYY"
                                        as={DateField}
                                        value={values.DOBG}
                                        onChange={(value) =>
                                          setFieldValue(`DOBG`, value, true)
                                        }
                                        onBlur={handleBlur}
                                        error={
                                          touched.DOBG && Boolean(errors.DOBG)
                                        }
                                        helperText={touched.DOBG && errors.DOBG}
                                      />
                                    </LocalizationProvider>
                                    &nbsp; &nbsp;
                                    <b>
                                      {values.DOBG &&
                                        `${moment().diff(
                                          values.DOBG
                                            ? new Date(values.DOBG)
                                            : null,
                                          "years"
                                        )} Year(s)`}
                                    </b>
                                  </Row>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      alignItems: "flex-end",
                                      display: "flex",
                                    }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Field
                                        disabled={familyID}
                                        name={`DOM`}
                                        label="Date of Marriage"
                                        variant="standard"
                                        size="small"
                                        format="DD-MM-YYYY"
                                        as={DateField}
                                        value={values.DOM}
                                        onChange={(value) =>
                                          setFieldValue(`DOM`, value, true)
                                        }
                                        onBlur={handleBlur}
                                        error={
                                          touched.DOM && Boolean(errors.DOM)
                                        }
                                        helperText={touched.DOM && errors.DOM}
                                      />
                                    </LocalizationProvider>
                                    &nbsp; &nbsp;
                                    <b>
                                      {values.DOM &&
                                        `${moment().diff(
                                          values.DOM
                                            ? new Date(values.DOM)
                                            : null,
                                          "years"
                                        )} Year(s)`}
                                    </b>
                                  </Row>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <Field
                                      disabled={familyID}
                                      name={`Health`}
                                      fullWidth
                                      label="Health"
                                      size="small"
                                      variant="standard"
                                      as={TextField}
                                      placeholder="Enter Health info"
                                      onBlur={handleBlur}
                                      error={
                                        touched.Health && Boolean(errors.Health)
                                      }
                                      helperText={
                                        touched.Health && errors.Health
                                      }
                                    />
                                  </Row>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <RadioGroup
                                      name="Gender"
                                      value={values.Gender}
                                      onChange={(e) => {
                                        setFieldValue("Gender", e);
                                      }}
                                      inline
                                      disabled={familyID}
                                    >
                                      <Radio value="Male">Male</Radio>
                                      <Radio value="Female">Female</Radio>
                                    </RadioGroup>
                                  </Row>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      marginLeft: -10,
                                    }}
                                  >
                                    <Autocomplete
                                      disabled={familyID}
                                      key="ID"
                                      name={`Work`}
                                      options={
                                        ProfessionList.status === "fetched"
                                          ? ProfessionList.data
                                          : [null]
                                      }
                                      getOptionLabel={(option) => option?.title}
                                      disableClearable={true}
                                      value={values.Work}
                                      onBlur={handleBlur}
                                      error={
                                        touched.Work && Boolean(errors.Work)
                                      }
                                      helperText={touched.Work && errors.Work}
                                      onChange={(_, newValue) => {
                                        console.log(values);
                                        console.log(errors);
                                        setFieldValue(`Work`, newValue, true);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="Work"
                                          onBlur={handleBlur}
                                          error={
                                            touched.Work && Boolean(errors.Work)
                                          }
                                          helperText={
                                            touched.Work && errors.Work
                                          }
                                          label="Profession"
                                          variant="standard"
                                        />
                                      )}
                                      style={{ width: 200 }}
                                    />
                                  </Row>
                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      marginLeft: -10,
                                    }}
                                  >
                                    <Autocomplete
                                      disabled={familyID}
                                      key="ID"
                                      name={`WorkType`}
                                      options={
                                        WorkTypeList.status === "fetched"
                                          ? WorkTypeList.data
                                          : [null]
                                      }
                                      getOptionLabel={(option) => option?.title}
                                      disableClearable={true}
                                      value={values.WorkType}
                                      onBlur={handleBlur}
                                      error={
                                        touched.WorkType &&
                                        Boolean(errors.WorkType)
                                      }
                                      helperText={
                                        touched.WorkType && errors.WorkType
                                      }
                                      onChange={(_, newValue) => {
                                        console.log(values);
                                        console.log(errors);
                                        setFieldValue(
                                          `WorkType`,
                                          newValue,
                                          true
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="WorkType"
                                          onBlur={handleBlur}
                                          error={
                                            touched.WorkType &&
                                            Boolean(errors.WorkType)
                                          }
                                          helperText={
                                            touched.WorkType && errors.WorkType
                                          }
                                          label="WorkType"
                                          variant="standard"
                                        />
                                      )}
                                      style={{ width: 200 }}
                                    />
                                  </Row>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <Field
                                      disabled={familyID}
                                      fullWidth
                                      name={`Education`}
                                      variant="standard"
                                      label="Education"
                                      size="small"
                                      as={TextField}
                                      placeholder="Enter Education Details"
                                      onBlur={handleBlur}
                                      error={
                                        touched.Education &&
                                        Boolean(errors.Education)
                                      }
                                      helperText={
                                        touched.Education && errors.Education
                                      }
                                    />
                                  </Row>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <Field
                                      disabled={familyID}
                                      fullWidth
                                      name={`WorkPlace`}
                                      variant="standard"
                                      label="WorkPlace"
                                      size="small"
                                      as={TextField}
                                      placeholder="Enter Work Place"
                                      onBlur={handleBlur}
                                      error={
                                        touched.WorkPlace &&
                                        Boolean(errors.WorkPlace)
                                      }
                                      helperText={
                                        touched.WorkPlace && errors.WorkPlace
                                      }
                                    />
                                  </Row>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <Field
                                      disabled={familyID}
                                      fullWidth
                                      name={`OldLIC`}
                                      variant="standard"
                                      label="OldLIC"
                                      size="small"
                                      as={TextField}
                                      placeholder="Enter If Old LIC"
                                      onBlur={handleBlur}
                                      error={
                                        touched.OldLIC && Boolean(errors.OldLIC)
                                      }
                                      helperText={
                                        touched.OldLIC && errors.OldLIC
                                      }
                                    />
                                  </Row>
                                  <Row gutter={0} style={{ marginBottom: 10 }}>
                                    <Field
                                      disabled={familyID}
                                      fullWidth
                                      name={`MobileNo`}
                                      variant="standard"
                                      label="MobileNo"
                                      size="small"
                                      as={TextField}
                                      placeholder="Enter MobileNo"
                                      onBlur={handleBlur}
                                      error={
                                        touched.MobileNo &&
                                        Boolean(errors.MobileNo)
                                      }
                                      helperText={
                                        touched.MobileNo && errors.MobileNo
                                      }
                                    />
                                  </Row>

                                  <Row
                                    gutter={0}
                                    style={{
                                      marginBottom: 10,
                                      marginLeft: -10,
                                    }}
                                  >
                                    <Autocomplete
                                      disabled={familyID}
                                      key="ID"
                                      name={`Relation`}
                                      options={
                                        FamilyList.status === "fetched"
                                          ? FamilyList.data
                                          : [null]
                                      }
                                      getOptionLabel={(option) => option?.title}
                                      disableClearable={true}
                                      value={values.Relation}
                                      onBlur={handleBlur}
                                      error={
                                        touched.Relation &&
                                        Boolean(errors.Relation)
                                      }
                                      helperText={
                                        touched.Relation && errors.Relation
                                      }
                                      onChange={(_, newValue) => {
                                        console.log(values);
                                        console.log(errors);
                                        setFieldValue(
                                          `Relation`,
                                          newValue,
                                          true
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="Relation"
                                          onBlur={handleBlur}
                                          error={
                                            touched.Relation &&
                                            Boolean(errors.Relation)
                                          }
                                          helperText={
                                            touched.Relation && errors.Relation
                                          }
                                          label="Relation"
                                          variant="standard"
                                        />
                                      )}
                                      style={{ width: 200 }}
                                    />
                                  </Row>
                                </Col>
                              </Row>
                            </Grid>
                          </div>
                          <Divider />
                          &nbsp; &nbsp;
                          {!familyID && (
                            <>
                              <LoadingButton
                                disabled={isSubmitting}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSubmit(e);
                                }}
                                loading={isValidating}
                                sx={{ my: 2 }}
                              >
                                {isSubmitting ? "Submitting..." : "Submit"}
                              </LoadingButton>
                            </>
                          )}
                        </div>
                      </form>
                    );
                  }}
                </Formik>
                <Col xs={24} sm={24} md={8}>
                  {familyID && (
                    <Row gutter={0} style={{ marginBottom: 10 }}>
                      <Uploader
                        fileListVisible={false}
                        multiple={false}
                        listType="picture"
                        action="https://LICBackend.avtechsolutions.site/Upload/AddProfilePic.php"
                        withCredentials={true}
                        headers={{
                          Authorization: `Bearer ${user?.SessionID}`,
                        }}
                        data={{ UID: familyID, Type: "FamilyMember" }}
                        onUpload={(File) => {
                          setUploading(true);
                          previewFile(File.blobFile, (value) => {
                            setFileInfo(value);
                          });
                        }}
                        onSuccess={(response, file) => {
                          setUploading(false);
                          toaster.push(
                            <Message type="success">
                              Uploaded successfully
                            </Message>
                          );
                          console.log(response);
                        }}
                        onError={(e) => {
                          console.log(e);
                          setFileInfo(null);
                          setUploading(false);
                          toaster.push(
                            <Message type="error">Upload failed</Message>
                          );
                        }}
                        onRemove={(file) => {
                          console.log(file);
                        }}
                      >
                        <button style={{ width: 300, height: 300 }}>
                          {uploading && <Loader backdrop center />}
                          {fileInfo ? (
                            <img
                              alt=""
                              src={fileInfo}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover" }}
                            />
                          ) : (
                            <AvatarIcon style={{ fontSize: 80 }} />
                          )}
                        </button>
                      </Uploader>

                      <Uploader
                        onRemove={(file) => {
                          console.log(file);
                        }}
                        listType="picture-text"
                        fileList={fileList1}
                        multiple={true}
                        data={{ UID: familyID, Type: "FamilyMember" }}
                        onChange={setFileList1}
                        withCredentials={true}
                        headers={{
                          Authorization: `Bearer ${user?.SessionID}`,
                        }}
                        action="https://LICBackend.avtechsolutions.site/Upload/UploadDocs.php"
                        renderFileInfo={(file, fileElement) => (
                          <span style={{ width: "100%", fontSize: 14 }}>
                            Name: {file.name}
                          </span>
                        )}
                      />
                    </Row>
                  )}
                </Col>
              </SideDrawer>
              <Drawer
                backdrop="static"
                open={openViewModal}
                onClose={handleViewModalClose}
                size="xs"
              >
                <Drawer.Header>
                  <Drawer.Title>Update Family Members</Drawer.Title>
                  <Drawer.Actions>
                    <Button
                      onClick={() => {
                        handleViewModalClose();
                        setFamilyID(null);
                        setFileInfo(null);
                        setFileList1([]);
                        setEditMode(false);
                      }}
                      appearance="primary"
                    >
                      Close
                    </Button>
                  </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                  <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                      UpdateFamily(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      setFieldValue,
                      handleSubmit,
                      isValidating,
                      isSubmitting,
                      touched,
                      handleBlur,
                      handleChange,
                    }) => {
                      // console.log(errors);
                      // console.log(values);
                      return (
                        <form>
                          <div>
                            <div className="row">
                              <Grid fluid style={{ padding: 4 }}>
                                <Row className="show-grid">
                                  <Col xs={24} sm={24} md={24}>
                                    <Row
                                      gutter={0}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Field
                                        disabled={!editMode}
                                        fullWidth
                                        name={`Name`}
                                        variant="standard"
                                        InputLabelProps={{
                                          shrink: Boolean(values.Name),
                                        }}
                                        label="Name"
                                        size="small"
                                        as={TextField}
                                        placeholder="Enter Name"
                                        onBlur={handleBlur}
                                        error={
                                          touched.Name && Boolean(errors.Name)
                                        }
                                        helperText={touched.Name && errors.Name}
                                      />
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{
                                        marginBottom: 10,
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DateField
                                          disabled={!editMode}
                                          label="Date Of Birth"
                                          variant="standard"
                                          size="small"
                                          name="DOBR"
                                          format="dd-MM-yyyy"
                                          value={values.DOBR}
                                          onChange={(value) =>
                                            setFieldValue("DOBR", value, true)
                                          }
                                          onBlur={handleBlur}
                                          error={
                                            touched.DOBR && Boolean(errors.DOBR)
                                          }
                                          helperText={
                                            touched.DOBR && errors.DOBR
                                          }
                                        />
                                      </LocalizationProvider>
                                      &nbsp; &nbsp;
                                      <b>
                                        {values.DOBR &&
                                          `${moment().diff(
                                            values.DOBR
                                              ? new Date(values.DOBR)
                                              : null,
                                            "years"
                                          )} Year(s)`}
                                      </b>
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{
                                        marginBottom: 10,
                                        alignItems: "flex-end",
                                        display: "flex",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <Field
                                          disabled={!editMode}
                                          name={`DOBG`}
                                          label="DOB (Greeting)"
                                          variant="standard"
                                          size="small"
                                          format="dd-MM-yyyy"
                                          as={DateField}
                                          value={values.DOBG}
                                          onChange={(value) =>
                                            setFieldValue(`DOBG`, value, true)
                                          }
                                          onBlur={handleBlur}
                                          error={
                                            touched.DOBG && Boolean(errors.DOBG)
                                          }
                                          helperText={
                                            touched.DOBG && errors.DOBG
                                          }
                                        />
                                      </LocalizationProvider>
                                      &nbsp; &nbsp;
                                      <b>
                                        {values.DOBG &&
                                          `${moment().diff(
                                            values.DOBG
                                              ? new Date(values.DOBG)
                                              : null,
                                            "years"
                                          )} Year(s)`}
                                      </b>
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{
                                        marginBottom: 10,
                                        alignItems: "flex-end",
                                        display: "flex",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <Field
                                          disabled={!editMode}
                                          name={`DOM`}
                                          label="Date of Marriage"
                                          variant="standard"
                                          size="small"
                                          format="dd-MM-yyyy"
                                          as={DateField}
                                          value={values.DOM}
                                          onChange={(value) =>
                                            setFieldValue(`DOM`, value, true)
                                          }
                                          onBlur={handleBlur}
                                          error={
                                            touched.DOM && Boolean(errors.DOM)
                                          }
                                          helperText={touched.DOM && errors.DOM}
                                        />
                                      </LocalizationProvider>
                                      &nbsp; &nbsp;
                                      <b>
                                        {values.DOM &&
                                          `${moment().diff(
                                            values.DOM
                                              ? new Date(values.DOM)
                                              : null,
                                            "years"
                                          )} Year(s)`}
                                      </b>
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Field
                                        disabled={!editMode}
                                        name={`Health`}
                                        fullWidth
                                        label="Health"
                                        size="small"
                                        InputLabelProps={{
                                          shrink: Boolean(values.Health),
                                        }}
                                        variant="standard"
                                        as={TextField}
                                        placeholder="Enter Health info"
                                        onBlur={handleBlur}
                                        error={
                                          touched.Health &&
                                          Boolean(errors.Health)
                                        }
                                        helperText={
                                          touched.Health && errors.Health
                                        }
                                      />
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <RadioGroup
                                        name="Gender"
                                        value={values.Gender}
                                        onChange={(e) => {
                                          setFieldValue("Gender", e);
                                        }}
                                        inline
                                        disabled={!editMode}
                                      >
                                        <Radio value="Male">Male</Radio>
                                        <Radio value="Female">Female</Radio>
                                      </RadioGroup>
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{
                                        marginBottom: 10,
                                        marginLeft: -10,
                                      }}
                                    >
                                      <Autocomplete
                                        key="ID"
                                        disabled={!editMode}
                                        name={`Work`}
                                        options={
                                          ProfessionList.status === "fetched"
                                            ? ProfessionList.data
                                            : [null]
                                        }
                                        getOptionLabel={(option) =>
                                          option?.title
                                        }
                                        disableClearable={true}
                                        value={
                                          Boolean(values.Work)
                                            ? values.Work
                                            : { value: "", title: "" }
                                        }
                                        onBlur={handleBlur}
                                        error={
                                          touched.Work && Boolean(errors.Work)
                                        }
                                        helperText={touched.Work && errors.Work}
                                        onChange={(_, newValue) => {
                                          console.log(values);
                                          console.log(errors);
                                          setFieldValue(`Work`, newValue, true);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="Work"
                                            onBlur={handleBlur}
                                            error={
                                              touched.Work &&
                                              Boolean(errors.Work)
                                            }
                                            helperText={
                                              touched.Work && errors.Work
                                            }
                                            label="Profession"
                                            variant="standard"
                                          />
                                        )}
                                        style={{ width: 200 }}
                                      />
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{
                                        marginBottom: 10,
                                        marginLeft: -10,
                                      }}
                                    >
                                      <Autocomplete
                                        disabled={!editMode}
                                        key="ID"
                                        name={`WorkType`}
                                        options={
                                          WorkTypeList.status === "fetched"
                                            ? WorkTypeList.data
                                            : [null]
                                        }
                                        getOptionLabel={(option) =>
                                          option?.title
                                        }
                                        disableClearable={true}
                                        value={
                                          Boolean(values.WorkType)
                                            ? values.WorkType
                                            : { value: "", title: "" }
                                        }
                                        onBlur={handleBlur}
                                        error={
                                          touched.WorkType &&
                                          Boolean(errors.WorkType)
                                        }
                                        helperText={
                                          touched.WorkType && errors.WorkType
                                        }
                                        onChange={(_, newValue) => {
                                          console.log(values);
                                          console.log(errors);
                                          setFieldValue(
                                            `WorkType`,
                                            newValue,
                                            true
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="WorkType"
                                            onBlur={handleBlur}
                                            error={
                                              touched.WorkType &&
                                              Boolean(errors.WorkType)
                                            }
                                            helperText={
                                              touched.WorkType &&
                                              errors.WorkType
                                            }
                                            label="WorkType"
                                            variant="standard"
                                          />
                                        )}
                                        style={{ width: 200 }}
                                      />
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Field
                                        disabled={!editMode}
                                        fullWidth
                                        name={`WorkPlace`}
                                        variant="standard"
                                        label="WorkPlace"
                                        size="small"
                                        as={TextField}
                                        placeholder="Enter Work Place"
                                        InputLabelProps={{
                                          shrink: Boolean(values.WorkPlace),
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.WorkPlace &&
                                          Boolean(errors.WorkPlace)
                                        }
                                        helperText={
                                          touched.WorkPlace && errors.WorkPlace
                                        }
                                      />
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Field
                                        disabled={!editMode}
                                        fullWidth
                                        name={`OldLIC`}
                                        variant="standard"
                                        label="OldLIC"
                                        size="small"
                                        as={TextField}
                                        placeholder="Enter If Old LIC"
                                        InputLabelProps={{
                                          shrink: Boolean(values.OldLIC),
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.OldLIC &&
                                          Boolean(errors.OldLIC)
                                        }
                                        helperText={
                                          touched.OldLIC && errors.OldLIC
                                        }
                                      />
                                    </Row>
                                    <Row
                                      gutter={0}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Field
                                        disabled={!editMode}
                                        fullWidth
                                        name={`MobileNo`}
                                        variant="standard"
                                        label="MobileNo"
                                        size="small"
                                        as={TextField}
                                        placeholder="Enter MobileNo"
                                        InputLabelProps={{
                                          shrink: Boolean(values.MobileNo),
                                        }}
                                        onBlur={handleBlur}
                                        error={
                                          touched.MobileNo &&
                                          Boolean(errors.MobileNo)
                                        }
                                        helperText={
                                          touched.MobileNo && errors.MobileNo
                                        }
                                      />
                                    </Row>

                                    <Row
                                      gutter={0}
                                      style={{
                                        marginBottom: 10,
                                        marginLeft: -10,
                                      }}
                                    >
                                      <Autocomplete
                                        disabled={!editMode}
                                        key="ID"
                                        name={`Relation`}
                                        options={
                                          FamilyList.status === "fetched"
                                            ? FamilyList.data
                                            : [null]
                                        }
                                        getOptionLabel={(option) =>
                                          option?.title
                                        }
                                        disableClearable={true}
                                        value={
                                          Boolean(values.Relation)
                                            ? values.Relation
                                            : { value: "", title: "" }
                                        }
                                        onBlur={handleBlur}
                                        error={
                                          touched.Relation &&
                                          Boolean(errors.Relation)
                                        }
                                        helperText={
                                          touched.Relation && errors.Relation
                                        }
                                        onChange={(_, newValue) => {
                                          console.log(values);
                                          console.log(errors);
                                          setFieldValue(
                                            `Relation`,
                                            newValue,
                                            true
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="Relation"
                                            onBlur={handleBlur}
                                            error={
                                              touched.Relation &&
                                              Boolean(errors.Relation)
                                            }
                                            helperText={
                                              touched.Relation &&
                                              errors.Relation
                                            }
                                            label="Relation"
                                            variant="standard"
                                          />
                                        )}
                                        style={{ width: 200 }}
                                      />
                                    </Row>
                                  </Col>
                                </Row>
                              </Grid>
                            </div>
                            <Divider />
                            &nbsp; &nbsp;
                            {editMode ? (
                              <>
                                <LoadingButton
                                  disabled={isSubmitting}
                                  type="submit"
                                  color="secondary"
                                  variant="contained"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit(e);
                                  }}
                                  loading={isValidating}
                                  sx={{ my: 2 }}
                                >
                                  {isSubmitting ? "Submitting..." : "Submit"}
                                </LoadingButton>
                                &nbsp; &nbsp;
                                <Button
                                  color="red"
                                  appearance="primary"
                                  onClick={Remove}
                                  sx={{ my: 2 }}
                                >
                                  {"Delete"}
                                </Button>
                              </>
                            ) : (
                              <Button
                                color="blue"
                                appearance="primary"
                                onClick={() => {
                                  setEditMode(true);
                                }}
                                sx={{ my: 2 }}
                              >
                                Edit
                              </Button>
                            )}
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                  <Col xs={24} sm={24} md={8}>
                    {initialValues?.UID && (
                      <>
                        <Row gutter={0} style={{ marginBottom: 10 }}>
                          <Uploader
                            fileListVisible={false}
                            disabled={!editMode}
                            multiple={false}
                            listType="picture"
                            action="https://LICBackend.avtechsolutions.site/Upload/AddProfilePic.php"
                            withCredentials={true}
                            headers={{
                              Authorization: `Bearer ${user?.SessionID}`,
                            }}
                            data={{
                              UID: initialValues.UID,
                              Type: "FamilyMember",
                            }}
                            onUpload={(File) => {
                              setUploading(true);
                              previewFile(File.blobFile, (value) => {
                                setFileInfo(value);
                              });
                            }}
                            onSuccess={(response, file) => {
                              setUploading(false);
                              toaster.push(
                                <Message type="success">
                                  Uploaded successfully
                                </Message>
                              );
                              console.log(response);
                            }}
                            onError={(e) => {
                              console.log(e);
                              setFileInfo(null);
                              setUploading(false);
                              toaster.push(
                                <Message type="error">Upload failed</Message>
                              );
                            }}
                            onRemove={(file) => {
                              console.log(file);
                            }}
                          >
                            <button style={{ width: 300, height: 300 }}>
                              {uploading && <Loader backdrop center />}
                              {fileInfo ? (
                                <img
                                  alt=""
                                  src={fileInfo}
                                  width="100%"
                                  height="100%"
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <AvatarIcon style={{ fontSize: 80 }} />
                              )}
                            </button>
                          </Uploader>
                        </Row>
                        <Row>
                          <Uploader
                            onRemove={(file) => {
                              console.log(file);
                            }}
                            disabled={!editMode}
                            listType="picture-text"
                            fileList={fileList1}
                            multiple={true}
                            data={{
                              UID: initialValues.UID,
                              Type: "FamilyMember",
                            }}
                            onChange={setFileList1}
                            withCredentials={true}
                            headers={{
                              Authorization: `Bearer ${user?.SessionID}`,
                            }}
                            action="https://LICBackend.avtechsolutions.site/Upload/UploadDocs.php"
                            renderFileInfo={(file, fileElement) => (
                              <span style={{ width: "100%", fontSize: 14 }}>
                                Name: {file.name}
                              </span>
                            )}
                          />
                        </Row>
                      </>
                    )}
                  </Col>

                  <Row
                    className="show-grid"
                    style={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Col xs={24} sm={24} md={24}>
                      {
                        <ul>
                          {docs.map((data) => (
                            <li key={data.UniqueID}>
                              {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                              <a
                                rel="noreferrer"
                                href={`https://LICBackend.avtechsolutions.site/Upload/assets/${data.Path}`}
                                target="_blank"
                                style={{ cursor: "pointer" }}
                                /* onClick={(e) => getDocs(e, data.UniqueID)} */
                              >
                                {data.Name}
                              </a>
                              {(permissions.includes(EDIT_PROSPECT) ||
                                user.Type === "SuperAdmin") &&
                                editMode && (
                                  <a
                                    href=""
                                    style={{
                                      paddingLeft: 5,
                                      textDecoration: "none",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleRemoveDoc(data.UniqueID)
                                    }
                                  >
                                    <DeleteForever style={{ fontSize: 15 }} />
                                  </a>
                                )}
                            </li>
                          ))}
                        </ul>
                      }
                    </Col>
                  </Row>
                </Drawer.Body>
              </Drawer>
            </>
            <SideDrawer
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <SideInputs />
            </SideDrawer>
          </Content>
        </Container>
      </Stack>
    </Box>
  );
}
