import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  TextField,
  useTheme,
  FormLabel,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useReducer, useState } from "react";
import * as yup from "yup";
import { Col, Grid, Heading, Row, Stack, Radio, RadioGroup } from "rsuite";
import { Uploader, Message, Loader, useToaster, Button as Btn } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import useAuth from "app/hooks/useAuth";
import { Formik, Field } from "formik";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { LoadingButton } from "@mui/lab";
import {
  DeleteForever,
  Image,
  ListAlt,
  NoteAdd,
  People,
  PictureAsPdf,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ADD_INPUT,
  DELETE_PROSPECT,
  EDIT_PROSPECT,
  VIEW_POLICY,
} from "Permissions";
import { LeadStatus, Status } from "app/DataSource";
import SideDrawer from "app/components/SideDrawer";
import SideInputs from "../Inputs/SideInputs";
import useAPINew from "app/hooks/useAPINew";
import { useProps } from "./ProspectProvider";
import { styled, lighten, darken } from "@mui/system";
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}

const today = new Date();
today.setHours(0, 0, 0, 0);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  Date: yup.date().max(today, "Date can not be in future"),
  Name: yup.string().required("Name is required"),
  RefBy: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  Caste: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  Village: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  Tehsil: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  District: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  State: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  Status: yup
    .string("Error")
    .required("You must select one of the valid options"),
  ConceptDiscussed: yup.string("Error").nullable(true),

  Address: yup
    .string("valid address is required")
    .required("Address is required"),
  WhatsAppNo: yup
    .string()
    .required("Mobile no required")
    .matches(phoneRegExp, "Mobile number is not valid"),
  MobileNo: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(true),
  Work: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  WorkType: yup.object().shape({
    title: yup
      .string("Error")
      .required("You must select one of the valid options"),
  }),
  YearlyIncome: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true), // Sets it as a compulsory field

  Investment: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true), // Sets it as a compulsory field

  OtherProperty: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true), // Sets it as a compulsory field
  BankLiability: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true), // Sets it as a compulsory field
  OtherLiability: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true), // Sets it as a compulsory field
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
export default function ViewProspect() {
  const [open, setOpen] = React.useState(false);
  const { UID } = useParams();
  const { user, permissions } = useAuth();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = React.useState({
    Date: new Date(),
    Name: "",
    Address: "",
    WhatsAppNo: "",
    MobileNo: "",
    RefBy: null,
    Caste: null,
    Village: null,
    Tehsil: null,
    District: null,
    State: null,
    Status: null,
    YearlyIncome: null,
    Investment: null,
    OtherProperty: null,
    BankLiability: null,
    OtherLiability: null,
    Result: null,
    LeadStatus: null,
  });
  const axiosPrivate = useAxiosPrivate();
  const CasteList = useAPINew("GetCasteList.php");
  const VillageList = useAPINew("GetVillageList.php");
  const TehsilList = useAPINew("GetTehsilList.php");
  const DistrictList = useAPINew("GetDistrictList.php");
  const StateList = useAPINew("GetStateList.php");
  const ProfessionList = useAPINew("GetProfessionList.php");
  const WorkTypeList = useAPINew("GetWorkTypeList.php");
  const RefList = useAPINew("GetRefDahiyaMitraList.php");
  const UsersList = useAPINew("GetListOfUsersToAssign.php");
  const options = (UsersList.error ? [null] : UsersList.data).map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const [docs, setDocs] = React.useState([]);
  const [policies, setPolicies] = React.useState([]);
  const [data, setData] = useState({});
  const [DiscussionNote, setDiscussionNote] = useState([]);
  const [isDeleted, setIsDeleted] = useState("0");
  const [discussionCount, setDiscussionCount] = useState(0);
  const [familyCount, setFamilyCount] = useState(0);
  const toaster = useToaster();
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);

  const [fileList1, setFileList1] = React.useState([]);

  const initialState = {
    EditMode: false,
    Attachment: false,
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "EDITABLE":
        return { ...initialState, EditMode: true };
      case "LOCKED":
        return { ...initialState, EditMode: false };
      case "ATTACH":
        return { ...initialState, Attachment: true };
      case "DONE":
        return { ...initialState, Attachment: false };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#FFCE54", "#A0D468", "#4FC1E9"];
  }, []);
  function handleMobileCheck(e) {
    if (e.target.value === "") {
      return;
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExist.php", { Mobile: e.target.value })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }
  const theme = useTheme();

  const { rowFiltereddata, updateIndexValue, index, setIndex } = useProps();
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getProspect();
      //console.log(rowFiltereddata);
      updateIndexValue(rowFiltereddata.indexOf(UID));
    }
    return () => {
      isSubscribed = false;
    };
  }, []);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getProspect();
    }
    return () => {
      isSubscribed = false;
    };
  }, [index, UID]);

  const goToPrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(
      `/Prospects/ViewProspect/${
        rowFiltereddata[index > 0 ? index - 1 : index]
      }`
    );
  };

  const goToNext = () => {
    setIndex((prevIndex) =>
      prevIndex < rowFiltereddata.length - 1 ? prevIndex + 1 : prevIndex
    );
    console.log(index);
    console.log(rowFiltereddata[index]);
    navigate(
      `/Prospects/ViewProspect/${
        rowFiltereddata[index < rowFiltereddata.length - 1 ? index + 1 : index]
      }`
    );
  };

  const getProspect = async () => {
    try {
      const responce = await axiosPrivate.post("GetProspectByID.php", { UID });
      //console.log(responce);
      const result = responce.data;
      if (result.status === 1) {
        const newData = {
          ...result.Data[0],
          Status: result.Data[0].Status,
          Caste:
            result.Data[0].Caste === ""
              ? null
              : { value: result.Data[0].Caste, title: result.Data[0].Caste },
          Village:
            result.Data[0].Village === ""
              ? null
              : {
                  value: result.Data[0].Village,
                  title: result.Data[0].Village,
                },
          Tehsil:
            result.Data[0].Tehsil === ""
              ? null
              : {
                  value: result.Data[0].Tehsil,
                  title: result.Data[0].Tehsil,
                },
          District:
            result.Data[0].District === ""
              ? null
              : {
                  value: result.Data[0].District,
                  title: result.Data[0].District,
                },
          State:
            result.Data[0].State === ""
              ? null
              : { value: result.Data[0].State, title: result.Data[0].State },
          Work:
            result.Data[0].Profession === ""
              ? null
              : {
                  value: result.Data[0].Profession,
                  title: result.Data[0].Profession,
                },
          WorkType:
            result.Data[0].WorkType === ""
              ? null
              : {
                  value: result.Data[0].WorkType,
                  title: result.Data[0].WorkType,
                },
          AssignedTo:
            result.Data[0].AssignedTo === ""
              ? null
              : {
                  value: result.Data[0].AssignedTo,
                  title: result.Data[0].AssignedToName,
                },
          RefBy:
            result.Data[0].RefBy === ""
              ? null
              : {
                  value: result.Data[0].RefBy,
                  title: result.Data[0].RefBy,
                },
          DiscussionDate:
            result.Data[0].DiscussionDate === "0000-00-00" ||
            result.Data[0].DiscussionDate === null
              ? null
              : new Date(result.Data[0].DiscussionDate),
          Date:
            result.Data[0].Date === "0000-00-00" || result.Data[0].Date === null
              ? null
              : result.Data[0].Date,
        };
        setInitialValues(newData);
        setIsDeleted(result.Data[0].Deleted);
        if (result.Data[0].AllDiscussions) {
          const notes = result.Data[0].AllDiscussions.split("//");
          setDiscussionNote(notes);
        }
        setFileInfo(
          `https://LICBackend.avtechsolutions.site/Upload/profile/${result.Data[0].Picture}`
        );
        setDocs(result.Docs);
        setPolicies(result.Policy);
        setData({ ...data, ...result.Data[0] });
        setDiscussionCount(result.Discussion);
        setFamilyCount(result.Family);
      }
      if (result.status === 0) {
        toaster.push(
          <Message type="error" bordered showIcon>
            <strong>Error!</strong> {result?.Error}
          </Message>
        );
      }
    } catch (error) {
      console.log(error);
      toaster.push(
        <Message type="error" bordered showIcon>
          <strong>Error!</strong> Error occured
        </Message>
      );
    }
  };
  function handleRemoveDoc(ID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this document.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemovePolicyDoc.php",
                    datatosend
                  );
                  const result = response.data;
                  onClose();
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                    const newDocs = docs.filter(function (item) {
                      return item.UniqueID !== UID;
                    });
                    setDocs(newDocs);
                  }

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  //navigate("/login", { state: { from: location }, replace: true });
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  const Remove = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to delete this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RemoveProspect.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };
  const Restore = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`You want to restore this details.?`}</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={async () => {
                try {
                  const datatosend = {
                    ID: UID,
                  };
                  const response = await axiosPrivate.post(
                    "/RestoreProspect.php",
                    datatosend
                  );
                  const result = response.data;
                  if (result.status === 1) {
                    toaster.push(
                      <Message type="success" header="Success">
                        {result.Success}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                  onClose();

                  if (result.status === 0) {
                    toaster.push(
                      <Message type="error" header="Error">
                        {`Error occured`}
                      </Message>,
                      { placement: "topEnd" },
                      { duration: 1000 }
                    );
                  }
                } catch (err) {
                  console.error(err);
                  onClose();
                }
              }}
            >
              Yes, Restore it!
            </button>
          </div>
        );
      },
    });
  };

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Heading
            level={4}
            style={{
              textAlign: "center",
              flex: 0.7,
              color: `#0c121d`,
            }}
          >
            {`${state.EditMode ? "Edit" : "View"} Prospect - (${
              initialValues.ID
            })`}
          </Heading>
          <Stack direction="row" spacing={2} style={{ flex: 0.3 }}>
            <Button
              onClick={() => {
                navigate(`/Prospects/ListFamilyMembers/${UID}`);
              }}
              appearance="link"
              startIcon={<People />}
            >
              {`Family Details (${familyCount})`}
            </Button>
            <Button
              onClick={() => {
                navigate(`/Prospects/ConceptDiscussion/${UID}`);
              }}
              appearance="link"
              startIcon={<NoteAdd />}
            >
              {`Discussion (${discussionCount})`}
            </Button>
            {(permissions.includes(ADD_INPUT) ||
              user.Type === "SuperAdmin") && (
              <Button
                appearance="link"
                onClick={() => {
                  setOpen(true);
                }}
                startIcon={<ListAlt />}
              >
                {" "}
                Inputs
              </Button>
            )}
          </Stack>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          maxHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
          overflowY: "auto",
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16} style={{ overflow: "auto" }}>
              <Row className="show-grid" style={{ marginTop: 10 }}>
                <p>
                  <b>Next Appointment : </b>
                  {data.NextAppointment}
                </p>
              </Row>
              <Row className="show-grid" style={{ marginTop: 10 }}>
                <p>
                  <b>Last Discussed On : </b>
                  {data.LastDiscussed}
                </p>
              </Row>
              <Row className="show-grid" style={{ marginTop: 10 }}>
                <p>
                  <b>Last Discussion Result : </b>
                  {data.LastDiscussionResult}
                </p>
              </Row>
              <Row className="show-grid" style={{ marginTop: 10 }}>
                <p>
                  <b>All Discussions : </b>
                  {DiscussionNote.map((a, index) => (
                    <p>{`${index + 1} - ${a}`}</p>
                  ))}
                </p>
              </Row>
              <br />
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  //console.log(errors);
                  confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div className="custom-ui">
                          <h1>{`Confirm?`}</h1>
                          <p>{`Are you sure to update this?`}</p>
                          <button
                            onClick={() => {
                              axiosPrivate
                                .post("EditProspect.php", {
                                  ...values,
                                  Caste: values.Caste?.value,
                                  Village: values.Village?.value,
                                  Tehsil: values.Tehsil?.value,
                                  District: values.District?.value,
                                  State: values.State?.value,
                                  Work: values.Work?.value,
                                  AssignedTo: values.AssignedTo?.value,
                                  WorkType: values.WorkType?.value,
                                  Date: Boolean(values?.Date)
                                    ? moment(values?.Date).format("YYYY-MM-DD")
                                    : null,
                                  DOB: Boolean(values?.DOB)
                                    ? moment(values?.DOB).format("YYYY-MM-DD")
                                    : null,
                                  DiscussionDate: Boolean(
                                    values?.DiscussionDate
                                  )
                                    ? moment(values?.DiscussionDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : null,

                                  UID,
                                  RefBy: values.RefBy?.value,
                                  RefByName:
                                    values.RefBy?.value === "OTHER" ||
                                    values.RefBy?.value === "CUSTOMER"
                                      ? values.RefByName
                                      : values.RefBy?.title,
                                })
                                .then((response) => {
                                  const result = response.data;
                                  if (result.status === 1) {
                                    toaster.push(
                                      <Message type="success" bordered showIcon>
                                        <strong>Success!</strong>{" "}
                                        {result.Success}
                                      </Message>
                                    );
                                    dispatch({ type: "LOCKED" });
                                    dispatch({ type: "ATTACH" });
                                    onClose();
                                  }
                                  if (result.status === 0) {
                                    toaster.push(
                                      <Message type="error" bordered showIcon>
                                        <strong>Error!</strong> {result.Error}
                                      </Message>
                                    );
                                    onClose();
                                  }
                                  setSubmitting(false);
                                  // resetForm();
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error submitting data:",
                                    error
                                  );

                                  onClose();
                                });
                            }}
                          >
                            Continue
                          </button>
                          <button
                            onClick={() => {
                              onClose();
                              setSubmitting(false);
                            }}
                          >
                            No
                          </button>
                        </div>
                      );
                    },
                  });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                  setErrors,
                  setTouched,
                }) => {
                  console.log(errors);

                  return (
                    <form className="target">
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading
                          level={6}
                          style={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          Category
                        </Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Status
                            </InputLabel>
                            <Select
                              readOnly={!state.EditMode}
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="Status"
                              name="Status"
                              value={values?.Status || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.Status && Boolean(errors.Status)}
                              helperText={touched.Status && errors.Status}
                            >
                              {[...Status, "POLICY SERVICE"].map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(
                                    name,
                                    [...Status, "POLICY SERVICE"],
                                    theme
                                  )}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 150 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label-LeadStatus">
                              Lead Status
                            </InputLabel>
                            <Select
                              readOnly={!state.EditMode}
                              labelId="demo-simple-select-standard-label-LeadStatus"
                              id="demo-simple-select-LeadStatus"
                              label="LeadStatus"
                              name="LeadStatus"
                              value={values?.LeadStatus || ""}
                              onChange={handleChange}
                              error={
                                touched.LeadStatus && Boolean(errors.LeadStatus)
                              }
                              helperText={
                                touched.LeadStatus && errors.LeadStatus
                              }
                            >
                              {[...LeadStatus, "POLICY SERVICE"].map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(
                                    name,
                                    [...LeadStatus, "POLICY SERVICE"],
                                    theme
                                  )}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateField
                              readOnly={!state.EditMode}
                              style={{ width: 100 }}
                              label="Date Of Birth"
                              variant="standard"
                              size="small"
                              name="DOB"
                              format="dd-MM-yyyy"
                              value={values.DOB}
                              onChange={(value) =>
                                setFieldValue("DOB", value, true)
                              }
                              onBlur={handleBlur}
                              error={touched.DOB && Boolean(errors.DOB)}
                              helperText={touched.DOB && errors.DOB}
                            />
                          </LocalizationProvider>
                          &nbsp; &nbsp;
                          <b>
                            {values.DOB &&
                              `${moment().diff(
                                values.DOB ? new Date(values.DOB) : null,
                                "years"
                              )} Year(s)`}
                          </b>
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Visit / Ref details</Heading>
                      </Row>
                      {/* <br /> */}
                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateField
                              readOnly={!state.EditMode}
                              label="Date"
                              variant="standard"
                              size="small"
                              name="Date"
                              format="dd-MM-yyyy"
                              value={values.Date}
                              onChange={(value) =>
                                setFieldValue("Date", value, true)
                              }
                              onBlur={handleBlur}
                              error={touched.Date && Boolean(errors.Date)}
                              helperText={touched.Date && errors.Date}
                            />
                          </LocalizationProvider>
                          &nbsp;&nbsp;
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="Place"
                            size="small"
                            variant="standard"
                            autoCapitalize={true}
                            name="Place"
                            InputLabelProps={{
                              shrink: Boolean(values.Place),
                              readOnly: !state.EditMode,
                            }}
                            value={values.Place}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.Place && Boolean(errors.Place)}
                            helperText={touched.Place && errors.Place}
                          />{" "}
                          &nbsp;&nbsp;
                          <Field
                            readOnly={!state.EditMode}
                            name="RefBy"
                            component={Autocomplete}
                            key="ID"
                            style={{ width: 150 }}
                            autoComplete={true}
                            autoHighlight={true}
                            options={RefList.error ? [null] : RefList.data}
                            getOptionLabel={(option) => option.title || ""}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, value) => {
                              setFieldValue(
                                "RefBy",
                                value || { value: "", title: "" }
                              );
                              console.log(errors);
                              console.log(values);
                            }}
                            value={
                              Boolean(values.RefBy)
                                ? values.RefBy
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="RefBy"
                                label="RefBy"
                                variant="standard"
                                error={touched.RefBy && Boolean(errors.RefBy)}
                                helperText={touched.RefBy && errors.RefBy}
                              />
                            )}
                          />
                          &nbsp;&nbsp;
                          {(values.RefBy?.value === "CUSTOMER" ||
                            values.RefBy?.value === "OTHER") && (
                            <TextField
                              InputProps={{
                                readOnly: !state.EditMode,
                              }}
                              label={`${
                                values.RefBy?.value === "CUSTOMER"
                                  ? "Customer"
                                  : "Ref By "
                              } Name`}
                              size="small"
                              variant="standard"
                              onBlur={handleBlur}
                              name="RefByName"
                              value={values.RefByName}
                              onChange={handleChange}
                              error={
                                touched.RefByName && Boolean(errors.RefByName)
                              }
                              helperText={touched.RefByName && errors.RefByName}
                            />
                          )}
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Personal details</Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <TextField
                            readOnly={!state.EditMode}
                            label="Name"
                            size="small"
                            variant="standard"
                            style={{ width: 250 }}
                            name="Name"
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            value={values.Name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.Name && Boolean(errors.Name)}
                            helperText={touched.Name && errors.Name}
                          />{" "}
                          &nbsp;&nbsp;
                          <Field
                            readOnly={!state.EditMode}
                            name="Caste"
                            component={Autocomplete}
                            key="ID"
                            options={CasteList.error ? [null] : CasteList.data}
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, d) => {
                              setFieldValue(
                                "Caste",
                                d || { value: "", title: "" }
                              );
                              console.log(errors);
                            }}
                            value={
                              Boolean(values.Caste)
                                ? values.Caste
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Caste"
                                label="Caste"
                                variant="standard"
                                error={touched.Caste && Boolean(errors.Caste)}
                                helperText={touched.Caste && errors.Caste}
                              />
                            )}
                          />
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <FormControl>
                            <FormLabel id="radio-buttons-group-label">
                              Gender
                            </FormLabel>

                            <RadioGroup
                              id="radio-buttons-group-label"
                              name="Gender"
                              inline
                              value={values.Gender}
                              onChange={(e) => {
                                setFieldValue("Gender", e);
                              }}
                            >
                              <Radio readOnly={!state.EditMode} value="Male">
                                Male
                              </Radio>
                              <Radio readOnly={!state.EditMode} value="Female">
                                Female
                              </Radio>
                            </RadioGroup>
                          </FormControl>
                          &nbsp; &nbsp;
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                          >
                            <InputLabel
                              shrink={Boolean(values.MaritalStatus)}
                              id="demo-simple-select-MaritalStatus"
                            >
                              Marital Status
                            </InputLabel>
                            <Select
                              readOnly={!state.EditMode}
                              labelId="demo-simple-select-MaritalStatus"
                              id="simple-select-MaritalStatus"
                              label="Marital Status"
                              name="MaritalStatus"
                              value={values?.MaritalStatus || null}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: Boolean(values.MaritalStatus),
                              }}
                              error={
                                touched.MaritalStatus &&
                                Boolean(errors.MaritalStatus)
                              }
                              helperText={
                                touched.MaritalStatus && errors.MaritalStatus
                              }
                            >
                              <MenuItem value="Single">Single</MenuItem>
                              <MenuItem value="Married">Married</MenuItem>
                              <MenuItem value="Divorced">Divorced</MenuItem>
                              <MenuItem value="Widowed">Widowed</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Contact details</Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="Address"
                            size="small"
                            variant="standard"
                            style={{ width: 300 }}
                            name="Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Address}
                            error={touched.Address && Boolean(errors.Address)}
                            helperText={touched.Address && errors.Address}
                          />{" "}
                          &nbsp;&nbsp;
                          <Field
                            readOnly={!state.EditMode}
                            name="Village"
                            component={Autocomplete}
                            key="ID"
                            options={
                              VillageList.error ? [null] : VillageList.data
                            }
                            getOptionLabel={(option) => option.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, data) => {
                              setFieldValue(
                                "Village",
                                data || { value: "", title: "" }
                              );
                            }}
                            value={
                              Boolean(values?.Village)
                                ? values?.Village
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Village"
                                label="Village/City"
                                variant="standard"
                                error={
                                  touched.Village && Boolean(errors.Village)
                                }
                                helperText={touched.Village && errors.Village}
                              />
                            )}
                          />
                          &nbsp;&nbsp;
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            style={{ width: 100 }}
                            label="PIN Code"
                            size="small"
                            variant="standard"
                            autoCapitalize={true}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.PIN),
                            }}
                            name="PIN"
                            value={values.PIN}
                            onChange={handleChange}
                            error={touched.PIN && Boolean(errors.PIN)}
                            helperText={touched.PIN && errors.PIN}
                          />
                        </Stack>
                      </Row>
                      <Row
                        gutter={6}
                        className="show-grid"
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <Stack direction="row" spacing={6}>
                          <Field
                            readOnly={!state.EditMode}
                            name="Tehsil"
                            component={Autocomplete}
                            key="ID"
                            options={
                              TehsilList.error ? [null] : TehsilList.data
                            }
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, data) => {
                              setFieldValue(
                                "Tehsil",
                                data || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values?.Tehsil)
                                ? values?.Tehsil
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Tehsil"
                                label="Tehsil"
                                variant="standard"
                                error={touched.Tehsil && Boolean(errors.Tehsil)}
                                helperText={touched.Tehsil && errors.Tehsil}
                              />
                            )}
                          />
                          <Field
                            readOnly={!state.EditMode}
                            name="District"
                            component={Autocomplete}
                            key="ID"
                            options={
                              DistrictList.error ? [null] : DistrictList.data
                            }
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, value) => {
                              setFieldValue(
                                "District",
                                value || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values.District)
                                ? values.District
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="District"
                                label="District"
                                variant="standard"
                                error={
                                  touched.District && Boolean(errors.District)
                                }
                                helperText={touched.District && errors.District}
                              />
                            )}
                          />
                          <Field
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            name="State"
                            component={Autocomplete}
                            key="ID"
                            options={StateList.error ? [null] : StateList.data}
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, value) => {
                              setFieldValue(
                                "State",
                                value || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values.State)
                                ? values.State
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="State"
                                label="State"
                                variant="standard"
                                error={touched.State && Boolean(errors.State)}
                                helperText={touched.State && errors.State}
                              />
                            )}
                          />
                        </Stack>
                      </Row>
                      <Row
                        gutter={6}
                        className="show-grid"
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <Stack direction="row" spacing={6}>
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="WhatsAppNo"
                            size="small"
                            variant="standard"
                            name="WhatsAppNo"
                            value={values.WhatsAppNo}
                            onBlur={(e) => {
                              handleBlur(e);
                              handleMobileCheck(e);
                            }}
                            onChange={handleChange}
                            error={
                              touched.WhatsAppNo && Boolean(errors.WhatsAppNo)
                            }
                            helperText={touched.WhatsAppNo && errors.WhatsAppNo}
                          />{" "}
                          &nbsp;&nbsp;
                          <TextField
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            label="RESI. MOB. NO."
                            size="small"
                            variant="standard"
                            name="MobileNo"
                            value={values.MobileNo}
                            onBlur={(e) => {
                              handleBlur(e);
                              handleMobileCheck(e);
                            }}
                            onChange={handleChange}
                            error={touched.MobileNo && Boolean(errors.MobileNo)}
                            helperText={touched.MobileNo && errors.MobileNo}
                          />{" "}
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Work / Education</Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <Field
                            readOnly={!state.EditMode}
                            name="Work"
                            component={Autocomplete}
                            key="ID"
                            options={
                              ProfessionList.error
                                ? [null]
                                : ProfessionList.data
                            }
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, data) => {
                              setFieldValue(
                                "Work",
                                data || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values?.Work)
                                ? values?.Work
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="Work"
                                label="Work"
                                variant="standard"
                                error={touched.Work && Boolean(errors.Work)}
                                helperText={touched.Work && errors.Work}
                              />
                            )}
                          />
                          &nbsp;&nbsp;
                          <Field
                            readOnly={!state.EditMode}
                            name="WorkType"
                            component={Autocomplete}
                            key="ID"
                            options={
                              WorkTypeList.error ? [null] : WorkTypeList.data
                            }
                            getOptionLabel={(option) => option?.title || ""}
                            style={{ width: 200 }}
                            onBlur={handleBlur} // so formik can see the forms touched state
                            onChange={(_, value) => {
                              setFieldValue(
                                "WorkType",
                                value || { value: "", title: "" }
                              );
                              console.log(values);
                            }}
                            value={
                              Boolean(values.WorkType)
                                ? values.WorkType
                                : { value: "", title: "" }
                            }
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="WorkType"
                                label="WorkType"
                                variant="standard"
                                error={
                                  touched.WorkType && Boolean(errors.WorkType)
                                }
                                helperText={touched.WorkType && errors.WorkType}
                              />
                            )}
                          />
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Other Financial Information</Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <TextField
                            readOnly={!state.EditMode}
                            label="Yearly Income"
                            size="small"
                            variant="standard"
                            name="YearlyIncome"
                            value={values.YearlyIncome}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.YearlyIncome),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            error={
                              touched.YearlyIncome &&
                              Boolean(errors.YearlyIncome)
                            }
                            helperText={
                              touched.YearlyIncome && errors.YearlyIncome
                            }
                          />{" "}
                          &nbsp;&nbsp;
                          <TextField
                            readOnly={!state.EditMode}
                            label="Investment"
                            size="small"
                            variant="standard"
                            name="Investment"
                            value={values.Investment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.Investment),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            error={
                              touched.Investment && Boolean(errors.Investment)
                            }
                            helperText={touched.Investment && errors.Investment}
                          />{" "}
                          &nbsp;&nbsp;
                          <TextField
                            readOnly={!state.EditMode}
                            label="Other Property"
                            size="small"
                            variant="standard"
                            name="OtherProperty"
                            value={values.OtherProperty}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.OtherProperty),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            error={
                              touched.OtherProperty &&
                              Boolean(errors.OtherProperty)
                            }
                            helperText={
                              touched.OtherProperty && errors.OtherProperty
                            }
                          />{" "}
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <TextField
                            readOnly={!state.EditMode}
                            label="Bank Liability"
                            size="small"
                            variant="standard"
                            name="BankLiability"
                            value={values.BankLiability}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(values.BankLiability),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            error={
                              touched.BankLiability &&
                              Boolean(errors.BankLiability)
                            }
                            helperText={
                              touched.BankLiability && errors.BankLiability
                            }
                          />{" "}
                          &nbsp;&nbsp;
                          <TextField
                            readOnly={!state.EditMode}
                            label="Other Liability"
                            size="small"
                            variant="standard"
                            name="OtherLiability"
                            value={values.OtherLiability}
                            InputLabelProps={{
                              shrink: Boolean(values.OtherLiability),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.OtherLiability &&
                              Boolean(errors.OtherLiability)
                            }
                            helperText={
                              touched.OtherLiability && errors.OtherLiability
                            }
                          />{" "}
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Other Details</Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <TextField
                            style={{ width: 250 }}
                            label="Other/LIC Details"
                            size="small"
                            variant="standard"
                            multiline={true}
                            maxRows={3}
                            name="Other"
                            value={values.Other}
                            InputLabelProps={{
                              shrink: Boolean(values.Other),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            onChange={handleChange}
                            error={touched.Other && Boolean(errors.Other)}
                            helperText={touched.Other && errors.Other}
                            readOnly={!state.EditMode}
                          />{" "}
                          <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              In Prospect?
                            </InputLabel>
                            <Select
                              readOnly={!state.EditMode}
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="Is Prospect?"
                              name="IsProspect"
                              value={values?.IsProspect || ""}
                              onChange={handleChange}
                              error={
                                touched.IsProspect && Boolean(errors.IsProspect)
                              }
                              helperText={
                                touched.IsProspect && errors.IsProspect
                              }
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Row>
                      <br />
                      <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Discussed</Heading>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={6}>
                          <TextField
                            readOnly={!state.EditMode}
                            style={{ width: 300 }}
                            label="First Meeting"
                            size="small"
                            variant="standard"
                            name="ConceptDiscussed"
                            multiline={true}
                            maxRows={3}
                            value={values.ConceptDiscussed}
                            InputLabelProps={{
                              shrink: Boolean(values.ConceptDiscussed),
                            }}
                            InputProps={{
                              readOnly: !state.EditMode,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.ConceptDiscussed &&
                              Boolean(errors.ConceptDiscussed)
                            }
                            helperText={
                              touched.ConceptDiscussed &&
                              errors.ConceptDiscussed
                            }
                          />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateField
                              readOnly={!state.EditMode}
                              label="Discussion Date"
                              variant="standard"
                              size="small"
                              name="DiscussionDate"
                              format="dd-MM-yyyy"
                              value={values.DiscussionDate}
                              onChange={(value) =>
                                setFieldValue("DiscussionDate", value, true)
                              }
                              onBlur={handleBlur}
                              error={
                                touched.DiscussionDate &&
                                Boolean(errors.DiscussionDate)
                              }
                              helperText={
                                touched.DiscussionDate && errors.DiscussionDate
                              }
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Row>

                      <Row gutter={6} className="show-grid">
                        <Stack direction="row" spacing={0}>
                          {user.Type === "SuperAdmin" && (
                            <Field
                              readOnly={!state.EditMode}
                              name="AssignedTo"
                              component={Autocomplete}
                              key="ID"
                              options={options.sort(
                                (a, b) =>
                                  -b.firstLetter.localeCompare(a.firstLetter)
                              )}
                              groupBy={(option) => option.UserType}
                              sx={{ width: 300 }}
                              renderGroup={(params) => (
                                <li key={params.key}>
                                  <GroupHeader>{params.group}</GroupHeader>
                                  <GroupItems>{params.children}</GroupItems>
                                </li>
                              )}
                              getOptionLabel={(option) => option?.title || ""}
                              style={{ width: 200 }}
                              onBlur={handleBlur} // so formik can see the forms touched state
                              onChange={(_, value) => {
                                setFieldValue(
                                  "AssignedTo",
                                  value || { value: "", title: "" }
                                );
                                console.log(values);
                              }}
                              value={
                                Boolean(values.AssignedTo)
                                  ? values.AssignedTo
                                  : { value: "", title: "" }
                              }
                              disableClearable={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="AssignedTo"
                                  label="AssignedTo"
                                  variant="standard"
                                  error={
                                    touched.AssignedTo &&
                                    Boolean(errors.AssignedTo)
                                  }
                                  helperText={
                                    touched.AssignedTo && errors.AssignedTo
                                  }
                                />
                              )}
                            />
                          )}
                          {/*                           <FormControl
                            variant="standard"
                            sx={{ mt: 3, minWidth: 200 }}
                          >
                            <InputLabel id="demo-simple-select-standard-label-Result">
                              Result
                            </InputLabel>
                            <Select
                              readOnly={!state.EditMode}
                              labelId="demo-simple-select-standard-label-Result"
                              id="demo-simple-select-Result"
                              label="Result"
                              name="Result"
                              value={values?.Result || ""}
                              onChange={handleChange}
                              error={touched.Result && Boolean(errors.Result)}
                              helperText={touched.Result && errors.Result}
                            >
                              {["Better", "Good", "Normal", "Negative"].map(
                                (name) => (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(
                                      name,
                                      ["Better", "Good", "Normal", "Negative"],
                                      theme
                                    )}
                                  >
                                    {name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl> */}
                          {/* <Button
                            onClick={() => {
                              navigate(`/Prospects/ConceptDiscussion/${UID}`);
                            }}
                            variant="contained"
                            startIcon={<NoteAdd />}
                          >
                            Discussion
                          </Button> */}
                        </Stack>
                      </Row>

                      {/* <Row gutter={6} style={{ marginBottom: 10 }}>
                        <Heading level={6}>Family Information</Heading>
                      </Row> */}
                      {/* <Row gutter={6} className="show-grid">
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="select-standard-family-label">
                            Family Members
                          </InputLabel>
                          <Select
                            readOnly={!state.EditMode}
                            labelId="select-standard-family-label"
                            id="simple-select--family-label"
                            multiple
                            label="Family Members"
                            MenuProps={MenuProps}
                            style={{ minWidth: 200 }}
                            name="FamilyList"
                            value={values?.FamilyList || []}
                            onChange={(e) => {
                              handleChange(e);
                              var newData = [];
                              for (
                                var i = 0;
                                i < (values?.FamilyList || []).length;
                                i++
                              ) {
                                newData[i] = {}; // creates a new object
                                newData[i].Relation = values?.FamilyList[i];
                              }
                              //console.log(newData);
                              setFamilyMembers(newData);
                              setData(values);
                            }}
                            error={
                              touched.FamilyList && Boolean(errors.FamilyList)
                            }
                            helperText={touched.FamilyList && errors.FamilyList}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {(FamilyList.status === "fetched"
                              ? FamilyList.data
                              : [null]
                            ).map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, FamilyList.data, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Row> */}
                      {/*                       <br />
                      <Row>
                        <Button
                          onClick={() => {
                            navigate(`/Prospects/ListFamilyMembers/${UID}`);
                          }}
                          variant="contained"
                          startIcon={<People />}
                        >
                          Family Details
                        </Button>
                      </Row> */}
                      <br />
                      <Row
                        gutter={1}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <Stack direction="row" spacing={4}>
                          {state.EditMode ? (
                            <>
                              <LoadingButton
                                disabled={isSubmitting}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                onClick={handleSubmit}
                                loading={isValidating}
                                sx={{ my: 2 }}
                              >
                                {isSubmitting ? "Submitting..." : "Submit"}
                              </LoadingButton>
                              <Btn
                                color="secondary"
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setErrors({});
                                  setTouched({}, false);
                                  dispatch({ type: "LOCKED" });
                                }}
                                sx={{ my: 2 }}
                              >
                                {"CANCEL"}
                              </Btn>
                            </>
                          ) : (
                            <>
                              {isDeleted === "0" &&
                                (permissions.includes(EDIT_PROSPECT) ||
                                  user.Type === "SuperAdmin") && (
                                  <Btn
                                    color="secondary"
                                    variant="contained"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch({ type: "EDITABLE" });
                                    }}
                                    sx={{ my: 2 }}
                                  >
                                    {"Edit"}
                                  </Btn>
                                )}
                              &nbsp; &nbsp;
                              {(permissions.includes(DELETE_PROSPECT) ||
                                user.Type === "SuperAdmin") && (
                                <>
                                  {isDeleted === "0" && (
                                    <Btn
                                      color="red"
                                      appearance="primary"
                                      onClick={Remove}
                                      sx={{ my: 2 }}
                                    >
                                      {"Delete"}
                                    </Btn>
                                  )}
                                  {isDeleted === "1" && (
                                    <Btn
                                      color="red"
                                      appearance="primary"
                                      onClick={Restore}
                                      sx={{ my: 2 }}
                                    >
                                      {"UnDelete"}
                                    </Btn>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Stack>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                <p>
                  <b>Added By : </b>
                  {data.AddedBy}
                </p>
              </Row>
              <Row className="show-grid">
                <p>
                  <b>Added On : </b>
                  {moment(data.AddedOn).format("DD-MM-YYYY HH:mm:ss")}
                </p>
              </Row>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  {UID && (
                    <Col xs={24} sm={24} md={24}>
                      <Uploader
                        fileListVisible={false}
                        disabled={!state.Attachment}
                        multiple={false}
                        listType="picture"
                        action="https://LICBackend.avtechsolutions.site/Upload/AddProfilePic.php"
                        withCredentials={true}
                        headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                        data={{ UID, Type: "Prospect" }}
                        onUpload={(File) => {
                          setUploading(true);
                          previewFile(File.blobFile, (value) => {
                            setFileInfo(value);
                          });
                        }}
                        onSuccess={(response, file) => {
                          setUploading(false);
                          toaster.push(
                            <Message type="success">
                              Uploaded successfully
                            </Message>
                          );
                          console.log(response);
                        }}
                        onError={(e) => {
                          console.log(e);
                          setFileInfo(null);
                          setUploading(false);
                          toaster.push(
                            <Message type="error">Upload failed</Message>
                          );
                        }}
                        onRemove={(file) => {
                          console.log(file);
                        }}
                      >
                        <button style={{ width: 300, height: 300 }}>
                          {uploading && <Loader backdrop center />}
                          {fileInfo ? (
                            <img
                              alt=""
                              src={fileInfo}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover" }}
                            />
                          ) : (
                            <AvatarIcon style={{ fontSize: 80 }} />
                          )}
                        </button>
                      </Uploader>

                      {state.Attachment && (
                        <Uploader
                          disabled={!state.Attachment}
                          removable={!state.Attachment}
                          onRemove={(file) => {
                            console.log(file);
                          }}
                          listType="picture-text"
                          fileList={fileList1}
                          multiple={true}
                          onSuccess={(response, file) => {
                            setUploading(false);
                            toaster.push(
                              <Message type="success">
                                Uploaded successfully
                              </Message>
                            );
                            console.log(response);
                          }}
                          onError={(e) => {
                            console.log(e);

                            toaster.push(
                              <Message type="error">Upload failed</Message>
                            );
                          }}
                          data={{ UID, Type: "Prospect" }}
                          onChange={setFileList1}
                          withCredentials={true}
                          headers={{
                            Authorization: `Bearer ${user?.SessionID}`,
                          }}
                          action="https://LICBackend.avtechsolutions.site/Upload/UploadDocs.php"
                          renderFileInfo={(file, fileElement) => (
                            <span style={{ width: "100%", fontSize: 14 }}>
                              {file.name}
                            </span>
                          )}
                        />
                      )}
                    </Col>
                  )}
                </Col>
              </Row>
              <Row
                className="show-grid"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <Col xs={24} sm={24} md={24}>
                  {
                    <ul>
                      {docs.map((data) => (
                        <li key={data.UniqueID}>
                          {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                          <a
                            rel="noreferrer"
                            href={`https://LICBackend.avtechsolutions.site/Upload/assets/${data.Path}`}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                            /* onClick={(e) => getDocs(e, data.UniqueID)} */
                          >
                            {data?.DocType === "pdf" ? (
                              <PictureAsPdf />
                            ) : (
                              <Image />
                            )}{" "}
                            {data.Name}
                          </a>
                          {(permissions.includes(EDIT_PROSPECT) ||
                            user.Type === "SuperAdmin") && (
                            <a
                              href=""
                              style={{
                                paddingLeft: 5,
                                textDecoration: "none",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveDoc(data.UniqueID);
                              }}
                            >
                              {state.Attachment && (
                                <DeleteForever style={{ fontSize: 15 }} />
                              )}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                </Col>
              </Row>
              {policies.length > 0 && (
                <Row
                  className="show-grid"
                  style={{ margin: "10px 0px 10px 0px" }}
                >
                  <Col xs={24} sm={24} md={24}>
                    <h6>Policies:</h6>
                    {
                      <ol>
                        {policies.map((data) => (
                          <li key={data.UniqueID}>
                            {/* <Link to={data.UniqueID}>{data.Name}</Link> */}
                            {(permissions.includes(VIEW_POLICY) ||
                              user.Type === "SuperAdmin") && (
                              <Link to={`/Policy/View/${data.UniqueID}`}>
                                {`${data.PolicyHolderName} - ${data.PolicyNo}`}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ol>
                    }
                  </Col>
                </Row>
              )}
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  <>
                    {index > 0 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToPrevious}
                        startIcon={<SkipPrevious />}
                      >
                        Previous
                      </Button>
                    )}

                    {index < rowFiltereddata.length - 1 && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#2979ff",
                          margin: 10,
                        }}
                        onClick={goToNext}
                        startIcon={<SkipNext />}
                      >
                        Next
                      </Button>
                    )}
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
        <SideDrawer
          open={open}
          onClose={() => {
            setOpen(false);
            CasteList.reload();
            VillageList.reload();
            TehsilList.reload();
            DistrictList.reload();
            StateList.reload();
            ProfessionList.reload();
            WorkTypeList.reload();
          }}
        >
          <SideInputs />
        </SideDrawer>
      </div>
    </>
  );
}
