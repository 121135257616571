import { createContext, useReducer } from "react";
import axios from "axios";
// CUSTOM COMPONENT
import { MatxLoading } from "app/components";

const initialState = {
  user: null,
  isInitialized: true,
  isAuthenticated: false,
  persist: false,
  roles: [5150, 1984, 2001],
  permissions: [201],
  accessToken: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        permissions: action.payload.permissions,
        persist: true,
      };
    }

    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        persist: false,
        permissions: [],
      };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,

  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /* const axiosPrivate = useAxiosPrivate(); */
  const login = async (email, password) => {
    const response = await axios.post(
      "https://LICBackend.avtechsolutions.site/Login.php",
      { Email: email, Password: password }
    );

    return response;

    //dispatch({ type: "LOGIN", payload: { user } });
  };
  const verifyOTP = async (email, password, OTP) => {
    const response = await axios.post(
      "https://LICBackend.avtechsolutions.site/VerifyOTP.php",
      { Email: email, Password: password, OTP }
    );
    const result = response.data;
    const user = result.Data;
    const permissions = result.Permissions;

    dispatch({ type: "LOGIN", payload: { user, permissions } });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };
  const setAuth = ({ user, roles, permissions, accessToken }) => {
    dispatch({
      type: "SET_AUTH",
      payload: { user, roles, permissions, accessToken },
    });
  };

  /* useEffect(() => {
    (async () => {
      try {
        const { data } = await axiosPrivate.post("/profile");
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: true, user: data.user },
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []); */

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, login, logout, verifyOTP, setAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
