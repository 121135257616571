import {
  Autocomplete,
  FormControl,
  InputLabel,
  TextField,
  useTheme,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useReducer, useState } from "react";
import * as yup from "yup";
import {
  Col,
  Divider,
  Grid,
  Heading,
  Panel,
  PanelGroup,
  Row,
  Stack,
} from "rsuite";
import { Uploader, Message, Loader, useToaster, Button } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { Drawer, Placeholder } from "rsuite";

import { useApi } from "app/hooks/useApi";

import useAxiosPrivate from "app/hooks/useAxiosPrivate";
import useAuth from "app/hooks/useAuth";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik, Field, Form, FieldArray } from "formik";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { LoadingButton } from "@mui/lab";
import { AttachFile, Delete } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { LeadStatus, Results, Status } from "app/DataSource";
function previewFile(file, callback) {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const today = new Date();
today.setHours(0, 0, 0, 0);
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  Date: yup.date().max(today, "Date cannot be in the future"),

  Name: yup.string("valid name is required").required("Name is required"),
  //Caste: yup.string("valid caste is required").required("Caste is required"),

  Status: yup.string().required("You must select one of the valid options"),
  ConceptDiscussed: yup.string("Error").required("You must enter valid data"),
  Result: yup
    .string("Error")
    .required("You must select one of the valid options"),
  LeadStatus: yup
    .string("Error")
    .required("You must select one of the valid options"),
  Address: yup
    .string("valid address is required")
    .required("Address is required"),
  WhatsAppNo: yup
    .string()
    .required("Mobile no required")
    .matches(phoneRegExp, "Mobile number is not valid"),
  MobileNo: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .nullable(true),
  Work: yup
    .string("valid profession is required")
    .required("Profession is required"),

  YearlyIncome: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true),
  Investment: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true),
  OtherProperty: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true),
  BankLiability: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .required("The field cannot be left blank.") // Sets it as a compulsory field
    .min(0, "Your income must be greater than 0!")
    .nullable(true),
  OtherLiability: yup
    .number("Must be a valid amount") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .min(0, "Your income must be greater than 0!")
    .nullable(true),
});

/* const defaultProps = {
  options: [{ title: "DONE", value: "DONE" }],
  getOptionLabel: (option) => option.title,
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
]; */

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function NewProspect() {
  const { user } = useAuth();
  /*   const initialValues = {
    Date: new Date(),
    Name: "",
    Address: "",
    WhatsAppNo: "",
    MobileNo: "",

    Caste: null,
    Village: null,
    Tehsil: null,
    District: null,
    State: null,
    Status: null,
    YearlyIncome: null,
    Investment: null,
    OtherProperty: null,
    BankLiability: null,
    OtherLiability: null,
    Result: null,
    LeadStatus: null,
  }; */

  const location = useLocation();
  const { ID } = location.state || {};
  const [initialValues, setInitialValues] = React.useState(
    location.state || {
      Date: new Date(),
      Name: "",
      Address: "",
      WhatsAppNo: "",
      MobileNo: "",

      Caste: null,
      Village: null,
      Tehsil: null,
      District: null,
      State: null,
      Status: null,
      YearlyIncome: null,
      Investment: null,
      OtherProperty: null,
      BankLiability: null,
      OtherLiability: null,
      Result: null,
      LeadStatus: null,
    }
  );
  const axiosPrivate = useAxiosPrivate();
  const CasteList = useApi("GetCasteList.php");
  const VillageList = useApi("GetVillageList.php");
  const TehsilList = useApi("GetTehsilList.php");
  const DistrictList = useApi("GetDistrictList.php");
  const StateList = useApi("GetStateList.php");
  const ProfessionList = useApi("GetProfessionList.php");
  const FamilyList = useApi("GetFamilyList.php");

  const [data, setData] = useState({});
  const [personName, setPersonName] = React.useState([]);
  const toaster = useToaster();
  const [uploading, setUploading] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState(null);
  const [backdrop, setBackdrop] = React.useState("static");
  const [open, setOpen] = React.useState(false);
  const [fileList1, setFileList1] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [UID, setUID] = React.useState(null);

  const [open1, setOpen1] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    console.log(ID);
    return () => {};
  }, []);
  const initialState = {
    DataAdded: false,
    FamilyAdded: false,
    DocumentAdded: false,
  };
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "New":
        return { ...initialState };
      case "NEW_ENTRY_DONE":
        return { ...initialState, DataAdded: true };
      case "FAMILY_ADDED":
        return { ...initialState, FamilyAdded: true };
      case "DOC_ADDED":
        return { ...initialState, DocumentAdded: true };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  const ColorStatus = React.useMemo(() => {
    return ["#FC6E51", "#FFCE54", "#A0D468", "#4FC1E9"];
  }, []);
  function handleMobileCheck(e) {
    if (e.target.value === "") {
      return;
    } else {
      try {
        axiosPrivate
          .post("/CheckIfMobileNoExist.php", { Mobile: e.target.value })
          .then((response) => {
            const result = response.data;
            if (result.status === 1) {
              if (result.App.length > 0) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="custom-ui">
                        <h1>{`Are you sure?`}</h1>
                        <p>{`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}</p>
                        <button onClick={onClose}>Continue</button>
                        <button
                          onClick={() => {
                            onClose();
                          }}
                        >
                          No
                        </button>
                      </div>
                    );
                  },
                });
                /*                 toaster.push(
                  <Message
                    showIcon
                    header="Are you sure?"
                    type="warning"
                    closable
                  >
                    <p>
                      {" "}
                      {`This Mobile no already registered with ${result.App.length} account(s) - ${result.Data[0].SrNo}. Continue?`}
                    </p>
                    <hr />
                    <ButtonToolbar>
                      <Button size="sm">Don't allow</Button>
                      <Button size="sm">Allow</Button>
                    </ButtonToolbar>
                  </Message>
                ); */
              }
            }
            if (result.status === 0) {
            }
          })
          .catch((err) => {
            console.error(err);
          });

        //navigate("/login", { state: { from: location }, replace: true });
      } catch (err) {
        console.error(err);
        //navigate("/login", { state: { from: location }, replace: true });
      }
    }
  }
  const theme = useTheme();
  function handleAttachment() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{`Are you sure?`}</h1>
            <p>{`Are you done with attaching documents?`}</p>
            <button
              onClick={() => {
                dispatch({ type: "DOC_ADDED" });
                onClose();
              }}
            >
              Yes
            </button>
            <button onClick={onClose}>No</button>
          </div>
        );
      },
    });
  }
  function SaveFamily(FamilyData) {
    const newData = FamilyData.family.map((data) => {
      return {
        ...data,
        DOBR: moment(data.DOBR?.$d).format("YYYY-MM-DD"),
        DOBG: moment(data.DOBG?.$d).format("YYYY-MM-DD"),
        DOM: moment(data.DOM?.$d).format("YYYY-MM-DD"),
        Work: data.Work.value,
        ReferenceID: UID,
      };
    });
    axiosPrivate
      .post("AddFamilyMember.php", { Family: newData })
      .then((response) => {
        const result = response.data;
        if (result.status === 1) {
          toaster.push(
            <Message type="success" bordered showIcon>
              <strong>Success!</strong> {result.Success}
            </Message>
          );
          dispatch({ type: "FAMILY_ADDED" });
        }
        if (result.status === 0) {
          toaster.push(
            <Message type="error" bordered showIcon>
              <strong>Error!</strong> {result.Error}
            </Message>
          );
        }

        // resetForm();
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  }

  return (
    <>
      <div
        style={{ width: "100%", border: "0.5px dashed #999", borderRadius: 10 }}
      >
        <Heading
          level={5}
          style={{
            textAlign: "center",
            color: `${
              data?.Status === "HOT"
                ? ColorStatus[0]
                : data.Status === "WARM"
                ? ColorStatus[1]
                : data.Status === "COLD"
                ? ColorStatus[2]
                : ColorStatus[3]
            }`,
          }}
        >
          Add Prospect
        </Heading>
      </div>
      <div
        style={{
          width: "100%",
          minHeight: 600,
          border: "0.5px dotted #999",
          marginTop: 5,
          borderRadius: 10,
        }}
      >
        <Grid fluid style={{ padding: 10 }}>
          <Row className="show-grid">
            <Col xs={24} sm={24} md={16} style={{ overflow: "auto" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  //console.log(values);
                  axiosPrivate
                    .post("AddProspect.php", {
                      ...values,
                      FamilyMembers: familyMembers,
                      Caste: values.Caste?.value,
                      Village: values.Village?.value,
                      Tehsil: values.Tehsil?.value,
                      District: values.District?.value,
                      State: values.State?.value,
                      Date: moment(values.Date).format("YYYY-MM-DD"),
                      FamilyList: (values?.FamilyList || []).toString(),
                    })
                    .then((response) => {
                      const result = response.data;
                      if (result.status === 1) {
                        toaster.push(
                          <Message type="success" bordered showIcon>
                            <strong>Success!</strong> {result.Success}
                          </Message>
                        );
                        setUID(result.ID);
                      }
                      if (result.status === 0) {
                        toaster.push(
                          <Message type="error" bordered showIcon>
                            <strong>Error!</strong> {result.Error}
                          </Message>
                        );
                      }
                      setSubmitting(false);
                      // resetForm();
                    })
                    .catch((error) => {
                      console.error("Error submitting data:", error);
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValidating,
                }) => (
                  <form className="target">
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading
                        level={6}
                        style={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Category
                      </Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-standard-label">
                            Status
                          </InputLabel>
                          <Select
                            disabled={UID}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="Status"
                            name="Status"
                            value={values?.Status || ""}
                            onChange={handleChange}
                            error={touched.Status && Boolean(errors.Status)}
                            helperText={touched.Status && errors.Status}
                          >
                            {Status.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, Status, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Personal details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="Name"
                          size="small"
                          variant="standard"
                          style={{ width: 250 }}
                          name="Name"
                          value={values.Name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.Name && Boolean(errors.Name)}
                          helperText={touched.Name && errors.Name}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          disabled={UID}
                          name="Caste"
                          component={Autocomplete}
                          key="ID"
                          options={
                            CasteList.status === "fetched"
                              ? CasteList.data
                              : [null]
                          }
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, d) => {
                            setFieldValue(
                              "Caste",
                              d || { value: "", title: "" }
                            );
                            console.log(errors);
                          }}
                          value={
                            Boolean(values.Caste)
                              ? values.Caste
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Caste"
                              label="Caste"
                              variant="standard"
                              error={touched.Caste && Boolean(errors.Caste)}
                              helperText={touched.Caste && errors.Caste}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Contact details</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="Address"
                          size="small"
                          variant="standard"
                          style={{ width: 300 }}
                          name="Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Address}
                          error={touched.Address && Boolean(errors.Address)}
                          helperText={touched.Address && errors.Address}
                        />{" "}
                        &nbsp;&nbsp;
                        <Field
                          disabled={UID}
                          name="Village"
                          component={Autocomplete}
                          key="ID"
                          options={
                            VillageList.status === "fetched"
                              ? VillageList.data
                              : [null]
                          }
                          getOptionLabel={(option) => option.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, data) => {
                            setFieldValue(
                              "Village",
                              data || { value: "", title: "" }
                            );
                          }}
                          value={
                            Boolean(values?.Village)
                              ? values?.Village
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Village"
                              label="Village/City"
                              variant="standard"
                              error={touched.Village && Boolean(errors.Village)}
                              helperText={touched.Village && errors.Village}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <Field
                          disabled={UID}
                          name="Tehsil"
                          component={Autocomplete}
                          key="ID"
                          options={
                            TehsilList.status === "fetched"
                              ? TehsilList.data
                              : [null]
                          }
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, data) => {
                            setFieldValue(
                              "Tehsil",
                              data || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values?.Tehsil)
                              ? values?.Tehsil
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Tehsil"
                              label="Tehsil"
                              variant="standard"
                              error={touched.Tehsil && Boolean(errors.Tehsil)}
                              helperText={touched.Tehsil && errors.Tehsil}
                            />
                          )}
                        />
                        <Field
                          disabled={UID}
                          name="District"
                          component={Autocomplete}
                          key="ID"
                          options={
                            DistrictList.status === "fetched"
                              ? DistrictList.data
                              : [null]
                          }
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "District",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.District)
                              ? values.District
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="District"
                              label="District"
                              variant="standard"
                              error={
                                touched.District && Boolean(errors.District)
                              }
                              helperText={touched.District && errors.District}
                            />
                          )}
                        />
                        <Field
                          disabled={UID}
                          name="State"
                          component={Autocomplete}
                          key="ID"
                          options={
                            StateList.status === "fetched"
                              ? StateList.data
                              : [null]
                          }
                          getOptionLabel={(option) => option?.title || ""}
                          style={{ width: 200 }}
                          onBlur={handleBlur} // so formik can see the forms touched state
                          onChange={(_, value) => {
                            setFieldValue(
                              "State",
                              value || { value: "", title: "" }
                            );
                            console.log(values);
                          }}
                          value={
                            Boolean(values.State)
                              ? values.State
                              : { value: "", title: "" }
                          }
                          disableClearable={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="State"
                              label="State"
                              variant="standard"
                              error={touched.State && Boolean(errors.State)}
                              helperText={touched.State && errors.State}
                            />
                          )}
                        />
                      </Stack>
                    </Row>
                    <Row
                      gutter={6}
                      className="show-grid"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="WhatsAppNo"
                          size="small"
                          variant="standard"
                          name="WhatsAppNo"
                          value={values.WhatsAppNo}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          error={
                            touched.WhatsAppNo && Boolean(errors.WhatsAppNo)
                          }
                          helperText={touched.WhatsAppNo && errors.WhatsAppNo}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={UID}
                          label="RESI. MOB. NO."
                          size="small"
                          variant="standard"
                          name="MobileNo"
                          value={values.MobileNo}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleMobileCheck(e);
                          }}
                          onChange={handleChange}
                          error={touched.MobileNo && Boolean(errors.MobileNo)}
                          helperText={touched.MobileNo && errors.MobileNo}
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Work / Education</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <Autocomplete
                          disabled={UID}
                          key="ID"
                          options={
                            ProfessionList.status === "fetched"
                              ? ProfessionList.data
                              : [null]
                          }
                          getOptionLabel={(option) => option?.title}
                          autoComplete={true}
                          autoHighlight={true}
                          blurOnSelect={true}
                          disableClearable={true}
                          onBlur={handleBlur}
                          name="Work"
                          value={values.Work}
                          onChange={(_, newValue) => {
                            setFieldValue(
                              "Work",
                              newValue || { value: "", title: "" },
                              true
                            );
                          }}
                          error={touched.Work && Boolean(errors.Work)}
                          helperText={touched.Work && errors.Work}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Profession"
                              variant="standard"
                            />
                          )}
                          style={{ width: 200 }}
                        />
                        &nbsp;&nbsp;
                        <TextField
                          disabled={UID}
                          label="Yearly Income"
                          size="small"
                          variant="standard"
                          name="YearlyIncome"
                          value={values.YearlyIncome}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.YearlyIncome && Boolean(errors.YearlyIncome)
                          }
                          helperText={
                            touched.YearlyIncome && errors.YearlyIncome
                          }
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Other Financial Information</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="Investment"
                          size="small"
                          variant="standard"
                          name="Investment"
                          value={values.Investment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.Investment && Boolean(errors.Investment)
                          }
                          helperText={touched.Investment && errors.Investment}
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={UID}
                          label="Other Property"
                          size="small"
                          variant="standard"
                          name="OtherProperty"
                          value={values.OtherProperty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.OtherProperty &&
                            Boolean(errors.OtherProperty)
                          }
                          helperText={
                            touched.OtherProperty && errors.OtherProperty
                          }
                        />{" "}
                      </Stack>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          label="Bank Liability"
                          size="small"
                          variant="standard"
                          name="BankLiability"
                          value={values.BankLiability}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.BankLiability &&
                            Boolean(errors.BankLiability)
                          }
                          helperText={
                            touched.BankLiability && errors.BankLiability
                          }
                        />{" "}
                        &nbsp;&nbsp;
                        <TextField
                          disabled={UID}
                          label="Other Liability"
                          size="small"
                          variant="standard"
                          name="OtherLiability"
                          value={values.OtherLiability}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.OtherLiability &&
                            Boolean(errors.OtherLiability)
                          }
                          helperText={
                            touched.OtherLiability && errors.OtherLiability
                          }
                        />{" "}
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Discussed</Heading>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <TextField
                          disabled={UID}
                          style={{ width: 300 }}
                          label="Concept Discussed"
                          size="small"
                          variant="standard"
                          name="ConceptDiscussed"
                          value={values.ConceptDiscussed}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.ConceptDiscussed &&
                            Boolean(errors.ConceptDiscussed)
                          }
                          helperText={
                            touched.ConceptDiscussed && errors.ConceptDiscussed
                          }
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateField
                            disabled={UID}
                            label="Date"
                            variant="standard"
                            size="small"
                            name="Date"
                            format="dd-MM-yyyy"
                            value={values.Date}
                            onChange={(value) =>
                              setFieldValue("Date", value, true)
                            }
                            onBlur={handleBlur}
                            error={touched.Date && Boolean(errors.Date)}
                            helperText={touched.Date && errors.Date}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Row>

                    <Row gutter={6} className="show-grid">
                      <Stack direction="row" spacing={6}>
                        <FormControl
                          variant="standard"
                          sx={{ mt: 3, minWidth: 200 }}
                        >
                          <InputLabel id="demo-simple-select-standard-label-Result">
                            Result
                          </InputLabel>
                          <Select
                            disabled={UID}
                            labelId="demo-simple-select-standard-label-Result"
                            id="demo-simple-select-Result"
                            label="Result"
                            name="Result"
                            value={values?.Result || ""}
                            onChange={handleChange}
                            error={touched.Result && Boolean(errors.Result)}
                            helperText={touched.Result && errors.Result}
                          >
                            {Results.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, Results, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl
                          variant="standard"
                          sx={{ mt: 3, minWidth: 200 }}
                        >
                          <InputLabel id="demo-simple-select-standard-label-LeadStatus">
                            Lead Status
                          </InputLabel>
                          <Select
                            disabled={UID}
                            labelId="demo-simple-select-standard-label-LeadStatus"
                            id="demo-simple-select-LeadStatus"
                            label="LeadStatus"
                            name="LeadStatus"
                            value={values?.LeadStatus || ""}
                            onChange={handleChange}
                            error={
                              touched.LeadStatus && Boolean(errors.LeadStatus)
                            }
                            helperText={touched.LeadStatus && errors.LeadStatus}
                          >
                            {LeadStatus.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, LeadStatus, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Row>
                    <br />
                    <Row gutter={6} style={{ marginBottom: 10 }}>
                      <Heading level={6}>Family Information</Heading>
                    </Row>
                    <Row gutter={6} className="show-grid">
                      <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="select-standard-family-label">
                          Family Members
                        </InputLabel>
                        <Select
                          disabled={UID}
                          labelId="select-standard-family-label"
                          id="simple-select--family-label"
                          multiple
                          label="Family Members"
                          MenuProps={MenuProps}
                          style={{ minWidth: 200 }}
                          name="FamilyList"
                          value={values?.FamilyList || []}
                          onChange={(e) => {
                            handleChange(e);
                            var newData = [];
                            for (
                              var i = 0;
                              i < (values?.FamilyList || []).length;
                              i++
                            ) {
                              newData[i] = {}; // creates a new object
                              newData[i].Relation = values?.FamilyList[i];
                            }
                            //console.log(newData);
                            setFamilyMembers(newData);
                            setData(values);
                          }}
                          error={
                            touched.FamilyList && Boolean(errors.FamilyList)
                          }
                          helperText={touched.FamilyList && errors.FamilyList}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {(FamilyList.status === "fetched"
                            ? FamilyList.data
                            : [null]
                          ).map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Row>
                    <br />
                    <Row gutter={1} style={{ marginTop: 10, marginBottom: 10 }}>
                      <Stack direction="row" spacing={4}>
                        {UID ? (
                          <>
                            {!state.FamilyAdded && (
                              <Button
                                onClick={() => {
                                  setOpen1(true);
                                }}
                                appearance="link"
                              >
                                Family Members
                              </Button>
                            )}
                            {!state.DocumentAdded && (
                              <LoadingButton
                                color="secondary"
                                onClick={handleAttachment}
                                loading={loading}
                                loadingPosition="Done"
                                startIcon={<AttachFile />}
                                variant="contained"
                              >
                                <span>Save</span>
                              </LoadingButton>
                            )}
                          </>
                        ) : (
                          <LoadingButton
                            disabled={isSubmitting}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit}
                            loading={isValidating}
                            sx={{ my: 2 }}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </LoadingButton>
                        )}
                      </Stack>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>

            <Col xs={24} sm={24} md={8}>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={24}>
                  {UID && (
                    <Col xs={24} sm={24} md={24}>
                      <Uploader
                        fileListVisible={false}
                        disabled={state.DocumentAdded}
                        multiple={false}
                        listType="picture"
                        action="https://LICBackend.avtechsolutions.site/Upload/AddProfilePic.php"
                        withCredentials={true}
                        headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                        data={{ UID, Type: "Prospect" }}
                        onUpload={(File) => {
                          setUploading(true);
                          previewFile(File.blobFile, (value) => {
                            setFileInfo(value);
                          });
                        }}
                        onSuccess={(response, file) => {
                          setUploading(false);
                          toaster.push(
                            <Message type="success">
                              Uploaded successfully
                            </Message>
                          );
                          console.log(response);
                        }}
                        onError={(e) => {
                          console.log(e);
                          setFileInfo(null);
                          setUploading(false);
                          toaster.push(
                            <Message type="error">Upload failed</Message>
                          );
                        }}
                        onRemove={(file) => {
                          console.log(file);
                        }}
                      >
                        <button style={{ width: 300, height: 300 }}>
                          {uploading && <Loader backdrop center />}
                          {fileInfo ? (
                            <img
                              alt=""
                              src={fileInfo}
                              width="100%"
                              height="100%"
                              style={{ objectFit: "cover" }}
                            />
                          ) : (
                            <AvatarIcon style={{ fontSize: 80 }} />
                          )}
                        </button>
                      </Uploader>

                      <Uploader
                        disabled={state.DocumentAdded}
                        removable={!state.DocumentAdded}
                        onRemove={(file) => {
                          console.log(file);
                        }}
                        listType="picture-text"
                        fileList={fileList1}
                        multiple={true}
                        onSuccess={(response, file) => {
                          setUploading(false);
                          toaster.push(
                            <Message type="success">
                              Uploaded successfully
                            </Message>
                          );
                          console.log(response);
                        }}
                        onError={(e) => {
                          console.log(e);

                          toaster.push(
                            <Message type="error">Upload failed</Message>
                          );
                        }}
                        data={{ UID, Type: "Prospect" }}
                        onChange={setFileList1}
                        withCredentials={true}
                        headers={{ Authorization: `Bearer ${user?.SessionID}` }}
                        action="https://LICBackend.avtechsolutions.site/Upload/UploadDocs.php"
                        renderFileInfo={(file, fileElement) => (
                          <span style={{ width: "100%", fontSize: 14 }}>
                            Name: {file.name}
                          </span>
                        )}
                      />
                    </Col>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
        <Drawer backdrop={backdrop} open={open} onClose={() => setOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>Drawer Title</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button onClick={() => setOpen(false)} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Placeholder.Paragraph />
          </Drawer.Body>
        </Drawer>
        <Drawer
          backdrop={backdrop}
          open={open1}
          onClose={() => setOpen1(false)}
          size="xs"
        >
          <Drawer.Header>
            <Drawer.Title>Add Family Members</Drawer.Title>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  setOpen1(false);
                }}
                appearance="primary"
              >
                Close
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body>
            <Formik
              initialValues={{
                family: [
                  {
                    Name: "",
                    DOBR: null,
                    DOBG: null,
                    DOM: null,
                    Health: null,
                    Work: null,
                    Relation: null,
                    WorkPlace: null,
                    OldLIC: null,
                    MobileNo: null,
                  },
                ],
              }}
              onSubmit={(values) => {
                SaveFamily(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <FieldArray name="family">
                    {({ remove, push }) => (
                      <div>
                        <PanelGroup accordion defaultActiveKey={0} bordered>
                          {values.family.length > 0 &&
                            values.family.map((_, index) => (
                              <div className="row" key={index}>
                                <Panel
                                  header={index + 1}
                                  eventKey={index + 1}
                                  aria-labelledby={index + 1}
                                >
                                  <Grid fluid style={{ padding: 4 }}>
                                    <Row className="show-grid">
                                      <Col xs={24} sm={24} md={24}>
                                        <Row
                                          gutter={0}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Field
                                            disabled={state.FamilyAdded}
                                            fullWidth
                                            name={`family.${index}.Name`}
                                            variant="standard"
                                            label="Name"
                                            size="small"
                                            as={TextField}
                                            placeholder="Enter Name"
                                          />
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{
                                            marginBottom: 10,
                                            display: "flex",
                                            alignItems: "flex-end",
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-in"
                                          >
                                            <Field
                                              disabled={state.FamilyAdded}
                                              name={`family.${index}.DOBR`}
                                              label="DOB (Record)"
                                              variant="standard"
                                              size="small"
                                              format="DD-MM-YYYY"
                                              as={DateField}
                                              value={values.family[index].DOBR}
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `family.${index}.DOBR`,
                                                  value,
                                                  true
                                                )
                                              }
                                            />
                                          </LocalizationProvider>
                                          &nbsp; &nbsp;
                                          <b>
                                            {values.family[index].DOBR &&
                                              `${moment().diff(
                                                values.family[index]?.DOBR
                                                  ? new Date(
                                                      values.family[index].DOBR
                                                    )
                                                  : null,
                                                "years"
                                              )} Year(s)`}
                                          </b>
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{
                                            marginBottom: 10,
                                            alignItems: "flex-end",
                                            display: "flex",
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-in"
                                          >
                                            <Field
                                              disabled={state.FamilyAdded}
                                              name={`family.${index}.DOBG`}
                                              label="DOB (Greeting)"
                                              variant="standard"
                                              size="small"
                                              format="DD-MM-YYYY"
                                              as={DateField}
                                              value={values.family[index].DOBG}
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `family.${index}.DOBG`,
                                                  value,
                                                  true
                                                )
                                              }
                                            />
                                          </LocalizationProvider>
                                          &nbsp; &nbsp;
                                          <b>
                                            {values.family[index]?.DOBG &&
                                              `${moment().diff(
                                                values.family[index]?.DOBG
                                                  ? new Date(
                                                      values.family[index]?.DOBG
                                                    )
                                                  : null,
                                                "years"
                                              )} Year(s)`}
                                          </b>
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{
                                            marginBottom: 10,
                                            alignItems: "flex-end",
                                            display: "flex",
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-in"
                                          >
                                            <Field
                                              disabled={state.FamilyAdded}
                                              name={`family.${index}.DOM`}
                                              label="Date of Marriage"
                                              variant="standard"
                                              size="small"
                                              format="DD-MM-YYYY"
                                              as={DateField}
                                              value={values.family[index].DOM}
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `family.${index}.DOM`,
                                                  value,
                                                  true
                                                )
                                              }
                                            />
                                          </LocalizationProvider>
                                          &nbsp; &nbsp;
                                          <b>
                                            {values.family[index].DOM &&
                                              `${moment().diff(
                                                values.family[index]?.DOM
                                                  ? new Date(
                                                      values.family[index].DOM
                                                    )
                                                  : null,
                                                "years"
                                              )} Year(s)`}
                                          </b>
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Field
                                            disabled={state.FamilyAdded}
                                            name={`family.${index}.Health`}
                                            fullWidth
                                            label="Health"
                                            size="small"
                                            variant="standard"
                                            as={TextField}
                                            placeholder="Enter Health info"
                                          />
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{
                                            marginBottom: 10,
                                            marginLeft: -10,
                                          }}
                                        >
                                          <Autocomplete
                                            disabled={state.FamilyAdded}
                                            key="ID"
                                            name={`family.${index}.Work`}
                                            options={
                                              ProfessionList.status ===
                                              "fetched"
                                                ? ProfessionList.data
                                                : [null]
                                            }
                                            getOptionLabel={(option) =>
                                              option?.title
                                            }
                                            disableClearable={true}
                                            value={values.family[index].Work}
                                            onChange={(_, newValue) => {
                                              setFieldValue(
                                                `family.${index}.Work`,
                                                newValue,
                                                true
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Profession"
                                                variant="standard"
                                              />
                                            )}
                                            style={{ width: 200 }}
                                          />
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Field
                                            disabled={state.FamilyAdded}
                                            fullWidth
                                            name={`family.${index}.WorkPlace`}
                                            variant="standard"
                                            label="WorkPlace"
                                            size="small"
                                            as={TextField}
                                            placeholder="Enter Work Place"
                                          />
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Field
                                            disabled={state.FamilyAdded}
                                            fullWidth
                                            name={`family.${index}.OldLIC`}
                                            variant="standard"
                                            label="OldLIC"
                                            size="small"
                                            as={TextField}
                                            placeholder="Enter If Old LIC"
                                          />
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Field
                                            disabled={state.FamilyAdded}
                                            fullWidth
                                            name={`family.${index}.MobileNo`}
                                            variant="standard"
                                            label="MobileNo"
                                            size="small"
                                            as={TextField}
                                            placeholder="Enter MobileNo"
                                          />
                                        </Row>
                                        <Row
                                          gutter={0}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Field
                                            disabled={state.FamilyAdded}
                                            fullWidth
                                            name={`family.${index}.Relation`}
                                            variant="standard"
                                            label="Relation"
                                            size="small"
                                            as={TextField}
                                            placeholder="Enter Relation"
                                          />
                                        </Row>
                                        <Row gutter={5}>
                                          {!state.FamilyAdded && (
                                            <button
                                              type="button"
                                              className="secondary"
                                              style={{ marginTop: 10 }}
                                              onClick={() => remove(index)}
                                            >
                                              <Delete />
                                            </button>
                                          )}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Grid>
                                </Panel>
                                <Divider />
                              </div>
                            ))}
                        </PanelGroup>
                        <Divider />
                        {!state.FamilyAdded && (
                          <>
                            <Button
                              color="orange"
                              appearance="primary"
                              onClick={() =>
                                push({
                                  Name: "",
                                  DOBR: null,
                                  DOBG: null,
                                  DOM: null,
                                  Health: null,
                                  Work: null,
                                  Relation: null,
                                  WorkPlace: null,
                                  OldLIC: null,
                                  MobileNo: null,
                                })
                              }
                            >
                              Add Member
                            </Button>
                            &nbsp; &nbsp;
                            <Button
                              type="submit"
                              color="green"
                              appearance="primary"
                            >
                              Save
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </Drawer.Body>
        </Drawer>
      </div>
    </>
  );
}
